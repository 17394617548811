import { filterTitles, radiusMappings } from '../util/filterMappings';
import {
  ButtonContainer,
  Container,
  FilterContainer,
  FilterSearchWrapper,
  LocationContainer,
  LocationText,
  Multiselect,
  RefineTitle,
  Select,
} from './Refine.style';
import { useSearch } from './useSearch';
import { MDSButton } from '@midwest/web/button';
import { FilterSearch } from '@yext/search-ui-react';

const searchFields = [
  {
    fieldApiName: 'builtin.location',
    entityType: 'location',
  },
];

export const Refine = () => {
  const {
    lifeStages,
    radius,
    handleSpecialtyFilter,
    handleLocationFilter,
    handleSearch,
    handleRadius,
  } = useSearch();

  return (
    <Container>
      <RefineTitle>Refine your search</RefineTitle>
      <FilterContainer>
        <Multiselect
          placeholder="Select advisor specialty"
          options={filterTitles}
          value={lifeStages}
          name="specialty"
          label="Financial advisor specialty"
          zIndex={9002}
          onChange={handleSpecialtyFilter}
        />
        <LocationContainer>
          <LocationText>Your location</LocationText>
          <FilterSearchWrapper>
            <FilterSearch
              placeholder="Zip or City, State"
              label=""
              key="directory-search"
              searchFields={searchFields}
              onSelect={handleLocationFilter}
            />
          </FilterSearchWrapper>
        </LocationContainer>
        <Select
          placeholder={`${radius.label || 'Select radius'}`}
          options={radiusMappings}
          value={radius.value}
          name="specialty"
          label="Radius"
          zIndex={9002}
          onChange={handleRadius}
        />
        <ButtonContainer>
          <MDSButton id="refine-advisor-search-results" onClick={handleSearch}>
            Refine
          </MDSButton>
        </ButtonContainer>
      </FilterContainer>
    </Container>
  );
};
