/* eslint-disable @typescript-eslint/no-explicit-any */
import { MultiselectOption } from '../Forms/Multiselect/types';
import { GroupBase, StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

export const CustomStyles = (
  theme: DefaultTheme,
): StylesConfig<MultiselectOption, boolean, GroupBase<MultiselectOption>> => ({
  container: (baseStyles, state) => ({
    ...baseStyles,
    width: '100%',
    borderRadius: 5,
    cursor: (state.selectProps as any).readOnly ? 'text' : 'pointer',
  }),
  control: (baseStyles, state) => ({
    display: 'flex',
    padding: (state.selectProps as any).readOnly ? '8px 14px' : '8px 12px',
    alignItems: 'center',
    gap: '12px',
    flex: '1 0 0',
    borderRadius: '5px',
    border:
      (state.selectProps as any).readOnly && !state.isDisabled
        ? 'none'
        : state.selectProps['aria-invalid']
          ? `2px solid ${theme.midwestColors.sentimentNegativeStrong}`
          : state.isDisabled
            ? `2px solid ${theme.midwestColors.borderDisabled}`
            : `2px solid ${theme.midwestColors.neutralLightHeavy}`,
    ':hover': {
      border:
        !state.selectProps.menuIsOpen &&
        !state.isDisabled &&
        !(state.selectProps as any).readOnly
          ? `2px solid ${theme.midwestColors.neutralDarkHeavy}`
          : '',
    },
    background:
      (state.selectProps as any).readOnly && !state.isDisabled
        ? `${theme.midwestColors.textSecondaryDarkBG}`
        : state.isDisabled
          ? `${theme.midwestColors.formBackgroundDisabled}`
          : `${theme.midwestColors.neutralLightWeak}`,
    boxSizing: 'border-box',
    minHeight: '48px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    color: theme.midwestColors.textPrimary,
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    border: 'none',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: 0,
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    color:
      (state.selectProps as any).readOnly && !state.isDisabled
        ? 'transparent'
        : state.isDisabled
          ? theme.midwestColors.textDisabled
          : theme.midwestColors.componentFormColorTextPlaceholder,
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    marginLeft: 0,
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    borderBottom: state.selectProps['aria-invalid']
      ? `2px solid ${theme.midwestColors.sentimentNegativeStrong}`
      : `2px solid ${theme.midwestColors.neutralLightHeavy}`,
    borderRight: state.selectProps['aria-invalid']
      ? `2px solid ${theme.midwestColors.sentimentNegativeStrong}`
      : `2px solid ${theme.midwestColors.neutralLightHeavy}`,
    borderLeft: state.selectProps['aria-invalid']
      ? `2px solid ${theme.midwestColors.sentimentNegativeStrong}`
      : `2px solid ${theme.midwestColors.neutralLightHeavy}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -4,
    borderTop: 'none',
    boxShadow: !(state.selectProps as any).isClicked
      ? `-4px 2px 0 0 ${theme.midwestColors.borderFocus}, -2px 4px 0 0 ${theme.midwestColors.borderFocus}, 4px 4px 0 0 ${theme.midwestColors.borderFocus};`
      : 'none',
    cursor: 'pointer',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    background: `${theme.midwestColors.neutralLightWeak}`,
    padding: 0,
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    pointerEvents: 'auto',
    zIndex: (state as any).selectProps.zIndex,
  }),
});
