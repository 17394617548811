'use client';

import { Advisor } from '../util/advisorSearch';
import { Profile } from './Profile';
import {
  Container,
  Controls,
  Image,
  NoResultsContainer,
  ResultsText,
  ResultsTitle,
  SpinnerOverlay,
  Title,
} from './Results.style';
import { useSearch } from './useSearch';
import { MDSPagination, PaginationSizes } from '@midwest/web/pagination';
import { Spinner } from '@thrivent/midwest-web';

export const Results = () => {
  const {
    page,
    handlePageChange,
    verticalResults,
    totalResultsCount,
    RESULTS_PER_PAGE,
  } = useSearch();

  if (!verticalResults) {
    return (
      <SpinnerOverlay>
        <Spinner />
      </SpinnerOverlay>
    );
  }
  const startIndex = page * RESULTS_PER_PAGE + 1;
  const endIndex = Math.min((page + 1) * RESULTS_PER_PAGE, totalResultsCount);
  const advisors = verticalResults.map((result) => {
    const { rawData, id } = result;

    const {
      address,
      c_title,
      c_fPName,
      c_designations,
      mainPhone,
      c_baseURL,
      name,
    } = rawData;

    return {
      id: id ?? '',
      fPName: c_fPName,
      title: c_title,
      designations: c_designations,
      phone: mainPhone,
      address: address,
      name: name,
      website: c_baseURL,
    } as Advisor;
  });

  return (
    <Container>
      <Controls>
        <Title>
          {totalResultsCount > 0
            ? `${
                startIndex === endIndex
                  ? `${startIndex}`
                  : `${startIndex}-${endIndex}`
              } of ${totalResultsCount} Results based off your search`
            : `0 Results based off your search`}
        </Title>
      </Controls>
      {advisors.map((advisor) => (
        <Profile key={advisor.id} advisor={advisor} />
      ))}
      {totalResultsCount > 0 ? (
        <MDSPagination
          itemCount={RESULTS_PER_PAGE}
          totalNumberOfItems={totalResultsCount}
          onPageChange={handlePageChange}
          paginationSize={PaginationSizes.MEDIUM}
          currentPage={page}
        />
      ) : (
        <NoResultsContainer>
          <Image
            src="https://cdn.thrivent.com/d0/30/55eb17e54109966d98bc467e82c0/thinking-man.svg"
            alt="thinking man"
            width={220}
            height={246}
          />
          <ResultsTitle>No results found</ResultsTitle>
          <ResultsText>Try adjusting your search criteria</ResultsText>
        </NoResultsContainer>
      )}
    </Container>
  );
};
