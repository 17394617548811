import { CTAConnectionOptionsStyles } from './cta-connection-options.style';
import { ChevronRight, IconCardProps } from '@exploration/shared-components';
import { useForceBreakpoint } from '@exploration/ui-utils';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import {
  launchLiveChat,
  useLiveChat,
} from '@thrivent-web/exploration/live-chat';
import {
  launchVirtualAssistantChat,
  useVirtualAssistantChat,
} from '@thrivent-web/exploration/virtual-assistant-chat';
import { useAtomValue } from 'jotai';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const {
  Section,
  Container,
  Title,
  CTAsContainer,
  BoxContainer,
  Badge,
  IconCard,
  LiveChatButton,
} = CTAConnectionOptionsStyles;

export interface CTAConnectionOptionsProps {
  headline: string;
  callToAction: IconCardProps[];
  id?: string;
  backgroundColor?: string;
  showNewButtonColor?: boolean;
}

// --------------------------------------------------------------------------

export const CTAConnectionOptions = ({
  headline,
  callToAction,
  backgroundColor: bgColor,
  id,
  showNewButtonColor,
}: CTAConnectionOptionsProps) => {
  const { midwestColors: colors } = useTheme();
  const backgroundColor = bgColor || colors.identityBrandTertiaryFaint;
  const baseDefaultProps = {
    forceMobileBreakpoint: useForceBreakpoint() === 'mobile',
  };
  Container.defaultProps = baseDefaultProps;
  CTAsContainer.defaultProps = baseDefaultProps;
  BoxContainer.defaultProps = baseDefaultProps;
  LiveChatButton.defaultProps = baseDefaultProps;

  const {
    isEnabled: isLiveChatEnabled,
    isLoading: isLiveChatLoading,
    isActive: isLiveChatActive,
    setLiveChat,
  } = useLiveChat();
  const {
    isEnabled: isVirtualChatEnabled,
    isLoading: isVirtualChatLoading,
    isActive: isVirtualChatActive,
    setVirtualAssistantChat,
  } = useVirtualAssistantChat();
  const {
    'show-virtual-assistant-chat': showVirtualAssistantChat,
    'show-live-chat': showLiveChat,
  } = useAtomValue(explorationFeatureFlagAtom);

  if (!callToAction) {
    return null;
  }

  const section1 = callToAction.find((cta) => {
    return cta.key === 'section-1';
  });
  const section2 = callToAction.find((cta) => {
    return cta.key === 'section-2';
  });
  const liveChatEnabledSection = callToAction.find((cta) => {
    return cta.key === 'section-live-chat-enabled';
  });
  const liveChatDisabledSection = callToAction.find((cta) => {
    return cta.key === 'section-live-chat-disabled';
  });
  const virtualAssistantSection = callToAction.find((cta) => {
    return cta.key === 'section-live-chat-virtual-assistant';
  });

  let chatCard = null;
  // Live Chat - Business hours active
  if (showLiveChat && isLiveChatEnabled && liveChatEnabledSection) {
    const { key, ...liveChatEnabledProps } = liveChatEnabledSection;
    chatCard = (
      <IconCard
        {...liveChatEnabledProps}
        key={key}
        headline={
          <>
            {liveChatEnabledSection?.headline}{' '}
            <Badge label="available now!" size="small" type="positive" />
          </>
        }
        buttonComponent={
          <LiveChatButton
            loading={isLiveChatLoading}
            onClick={() => launchLiveChat(setLiveChat)}
            disabled={isLiveChatActive}
            data-testid="live-chat-button"
            showDarkerBlueButton={!!showNewButtonColor}
          >
            {liveChatEnabledSection?.text} <ChevronRight />
          </LiveChatButton>
        }
      />
    );
  }
  // Live Chat - Business hours inactive
  else if (showLiveChat && !isLiveChatEnabled && liveChatDisabledSection) {
    chatCard = <IconCard {...liveChatDisabledSection} />;
  }
  // Virtual Assistant Chat
  else if (
    showVirtualAssistantChat &&
    virtualAssistantSection &&
    isVirtualChatEnabled
  ) {
    const { key, ...virtualAssistantProps } = virtualAssistantSection;
    chatCard = (
      <IconCard
        {...virtualAssistantProps}
        key={key}
        headline={
          <>
            {virtualAssistantSection?.headline}{' '}
            <Badge label="New!" size="small" type="positive" />
          </>
        }
        buttonComponent={
          <LiveChatButton
            loading={isVirtualChatLoading}
            onClick={() => launchVirtualAssistantChat(setVirtualAssistantChat)}
            disabled={isVirtualChatActive}
            id={process.env['NEXT_PUBLIC_SF_VIRTUAL_ASSISTANT_CHAT_BUTTON_ID']}
            data-testid="virtual-assistant-chat-button"
            showDarkerBlueButton={!!showNewButtonColor}
          >
            {virtualAssistantSection?.text} <ChevronRight />
          </LiveChatButton>
        }
      />
    );
  }

  const Section1 = () => {
    if (!section1) {
      return;
    }
    const { key, ...section1Props } = section1;
    return (
      <BoxContainer>
        <IconCard
          {...section1Props}
          key={key}
          showDarkerBlueButton={showNewButtonColor}
        />
      </BoxContainer>
    );
  };

  const Section2 = () => {
    if (!section2) {
      return;
    }
    const { key, ...section2Props } = section2;
    return (
      <BoxContainer>
        <IconCard
          {...section2Props}
          key={key}
          showDarkerBlueButton={showNewButtonColor}
        />
      </BoxContainer>
    );
  };

  return (
    <Section id={id} backgroundColor={backgroundColor}>
      <Container>
        <Title as="h2">{headline}</Title>
        <CTAsContainer>
          <Section1 />
          {chatCard && <BoxContainer>{chatCard}</BoxContainer>}
          <Section2 />
        </CTAsContainer>
      </Container>
    </Section>
  );
};
