import { MDSButton, MDSText } from '@midwest/web/base';
import { ChevronLeft, ChevronRight } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const PageButton = styled(MDSButton ?? 'span')<{ selected?: boolean }>`
  padding: 10px;
  background: none;
  border: none;

  height: 46px;
  width: 46px;

  &:hover,
  &:focus {
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
    border: none;
  }

  &:focus-visible {
    background: ${(p) =>
      p.theme.midwestColors.identityBrandPrimaryFaint} !important;
    box-shadow: 0 0 1px 0.25rem ${(p) => p.theme.midwestColors.borderFocus};
  }

  &:focus {
    background: none;
    border: none;
  }
`;

export const PageButtonTextContainer = styled.div<{
  selected?: boolean;
}>`
  width: 25px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: ${(p) => p.theme.border.widthDefault} solid
    ${(p) =>
      p.selected ? p.theme.midwestColors.identityBrandPrimaryHeavy : 'none'};
  background: ${(p) =>
    p.selected
      ? p.theme.midwestColors.identityBrandPrimaryStrong
      : 'transparent'};
`;

export const PageButtonText = styled(MDSText.BodyHeavySm)<{
  selected?: boolean;
}>`
  color: ${(p) =>
    p.selected
      ? p.theme.midwestColors.textPrimaryDarkBG
      : p.theme.midwestColors.textPrimary};
`;

export const PrevButton = styled(PageButton)<{ disabled?: boolean }>`
  fill: ${(p) =>
    p.disabled ? p.theme.midwestColors.neutralLightStrong : 'initial'};
  background: transparent;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${(p) => p.theme.radius.radiusMedium};
  box-shadow: none;
  pointer-events: all;
  border: none;

  &:hover,
  &:focus {
    border: none;
    background: ${(p) =>
      p.disabled
        ? 'transparent'
        : `${p.theme.midwestColors.identityBrandPrimaryFaint}`};
  }
`;

export const NextButton = styled(PageButton)<{ disabled?: boolean }>`
  fill: ${(p) =>
    p.disabled ? p.theme.midwestColors.neutralLightStrong : 'initial'};
  background: transparent;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${(p) => p.theme.radius.radiusMedium};
  box-shadow: none;
  pointer-events: all;
  border: none;

  &:hover,
  &:focus {
    border: none;
    background: ${(p) =>
      p.disabled
        ? 'transparent'
        : `${p.theme.midwestColors.identityBrandPrimaryFaint}`};
  }
`;
export const MorePages = styled(PageButton)`
  cursor: default;
  align-self: center;
  background: ${(p) => p.theme.midwestColors.backgroundSecondary};
  border: none;

  height: 24px;
  width: 29px;

  &:hover,
  &:focus {
    background: ${(p) => p.theme.midwestColors.backgroundSecondary};
    border: none;
  }

  span {
    background: none;
    color: #686b6b;
  }
`;

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.xxsm};

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const ChevronLeftIcon = styled((props) => <ChevronLeft {...props} />)<{
  disabled?: boolean;
}>`
  fill: inherit;
  width: 18px;
  height: 18px;
`;

export const ChevronRightIcon = styled((props) => <ChevronRight {...props} />)<{
  disabled?: boolean;
}>`
  fill: inherit;
  width: 18px;
  height: 18px;
`;

export const HiddenAriaLive = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const LastItemContainer = styled.div`
  display: flex;
`;
