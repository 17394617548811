import { HeadlessPageData } from '@exploration/shared-types';
import { atom } from 'jotai';

// --------------------------------------------------------------------------

export const headlessPageDataAtom = atom<HeadlessPageData>({
  metadata: {
    title: '',
    description: '',
  },
  image: {},
  text: {},
  callToAction: {},
  list: {},
} as HeadlessPageData);
