'use client';

import {
  CustomContainer,
  CustomCreatableSelect,
  CustomDropdownIndicator,
  CustomIndicatorSeparator,
  CustomMultiSelect,
  CustomMultiValueLabel,
  CustomMultiValueRemove,
  CustomNoOptionsMessage,
  CustomPlaceholder,
  CustomValueOption,
  MultiselectCustomOption,
} from '../../ReactSelect/CustomComponents';
import { Error, Wrapper } from '../../ReactSelect/StyledComponents';
import { getMultiselectStyles } from './Multiselect.styles';
import { MultiselectOption, MultiselectProps } from './types';
import {
  FormComponentLayout,
  HelperText,
  LabelAndLink,
} from '@midwest/web/shared';
import { ComponentType, forwardRef, useState } from 'react';
import {
  ActionMeta,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueGenericProps,
  PlaceholderProps,
} from 'react-select';
import { useTheme } from 'styled-components';

/**
 ```jsx
 import { MDSMultiselect } from '@midwest/web/forms';
 ```
 * The multiselect is a version of the typeahead component. Allows users to add multiple selections to a single form fields.
 */
export const MDSMultiselect = forwardRef<HTMLInputElement, MultiselectProps>(
  (
    {
      name,
      label,
      disabled = false,
      required = false,
      readOnly = false,
      placeholder,
      errorText,
      linkLabel,
      linkHref,
      invalid = undefined,
      helperText,
      options,
      allowAddNewItem,
      onChange,
      value,
      zIndex,
      validationText,
      menuPosition,
      captureMenuScroll,
      ...rest
    },
    ref,
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [optionsState, setOptionsState] = useState<MultiselectOption[]>(
      options ?? [],
    );
    const [focused, setFocused] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const theme = useTheme();

    const handleChangeCreatable = (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>,
    ) => {
      if (actionMeta.action === 'create-option') {
        const option = newValue as MultiselectOption[];

        const newOption: MultiselectOption = {
          label: option[option.length - 1].label,
          value: option[option.length - 1].value,
        };
        setOptionsState([...optionsState, newOption]);
      }

      if (onChange) {
        onChange(
          newValue as MultiselectOption[],
          actionMeta as ActionMeta<MultiselectOption>,
        );
      }
    };

    const handleOpenChange = (isOpen: boolean) => {
      setIsMenuOpen(isOpen);
      setFocused(!focused);
    };

    const multiselectHandleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !isMenuOpen) {
        e.preventDefault();
        setIsMenuOpen(true);
      }
    };

    const MultiSelectComponents = {
      ValueContainer: CustomValueOption,
      MultiValueLabel: CustomMultiValueLabel as ComponentType<
        MultiValueGenericProps<
          MultiselectOption,
          boolean,
          GroupBase<MultiselectOption>
        >
      >,
      MultiValueRemove: CustomMultiValueRemove,
      DropdownIndicator: CustomDropdownIndicator as ComponentType<
        DropdownIndicatorProps<
          MultiselectOption,
          boolean,
          GroupBase<MultiselectOption>
        >
      >,
      IndicatorSeparator: CustomIndicatorSeparator,
      Option: MultiselectCustomOption,
      NoOptionsMessage: CustomNoOptionsMessage,
      Placeholder: CustomPlaceholder as ComponentType<
        PlaceholderProps<
          MultiselectOption,
          boolean,
          GroupBase<MultiselectOption>
        >
      >,
      SelectContainer: CustomContainer,
    };

    // This is a workaround for document.body not being available server side
    const documentBody =
      typeof document !== 'undefined' ? document.body : undefined;

    return (
      <FormComponentLayout {...rest}>
        <LabelAndLink
          label={label}
          linkLabel={linkLabel}
          linkHref={linkHref}
          disabled={disabled}
          focused={isMenuOpen}
          labelId={name}
          validationText={validationText}
          readOnly={readOnly}
        />

        {!!helperText && <HelperText>{helperText}</HelperText>}

        <Wrapper
          onMouseDown={() => setIsClicked(true)}
          onBlur={() => setIsClicked(false)}
          data-testid="wrapper"
          $disabled={disabled}
          $isClicked={isClicked}
        >
          {allowAddNewItem ? (
            <CustomCreatableSelect
              aria-labelledby="aria-label"
              aria-label={readOnly ? `read only ${label}` : label}
              aria-readonly={readOnly}
              isClicked={isClicked}
              ref={ref}
              name={name}
              menuIsOpen={readOnly ? false : isMenuOpen}
              menuPortalTarget={documentBody}
              menuPosition={menuPosition}
              isMulti
              isDisabled={disabled}
              hideSelectedOptions={false}
              aria-required={required}
              closeMenuOnSelect={false}
              captureMenuScroll={captureMenuScroll}
              placeholder={placeholder ? placeholder : ''}
              aria-invalid={invalid}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              styles={getMultiselectStyles(theme)}
              options={optionsState}
              onMenuOpen={() => handleOpenChange(true)}
              onMenuClose={() => handleOpenChange(false)}
              components={MultiSelectComponents}
              onChange={handleChangeCreatable}
              onKeyDown={multiselectHandleKeyDown}
              value={value}
              readOnly={readOnly}
              ariaLiveMessages={
                readOnly
                  ? {
                      guidance: () => {
                        return 'This field is read only';
                      },
                    }
                  : undefined
              }
              zIndex={zIndex}
            />
          ) : (
            <CustomMultiSelect
              aria-labelledby="aria-label"
              aria-label={readOnly ? `read only ${label}` : label}
              aria-readonly={readOnly}
              isClicked={isClicked}
              ref={ref}
              name={name}
              menuIsOpen={readOnly ? false : isMenuOpen}
              menuPortalTarget={documentBody}
              menuPosition={menuPosition}
              isMulti
              isDisabled={disabled}
              hideSelectedOptions={false}
              aria-required={required}
              closeMenuOnSelect={false}
              captureMenuScroll={captureMenuScroll}
              placeholder={placeholder ? placeholder : ''}
              aria-invalid={invalid}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              styles={getMultiselectStyles(theme)}
              options={options}
              onMenuOpen={() => handleOpenChange(true)}
              onMenuClose={() => handleOpenChange(false)}
              components={MultiSelectComponents}
              onChange={(newValue: unknown, actionMeta: ActionMeta<unknown>) =>
                onChange &&
                onChange(
                  newValue as MultiselectOption[],
                  actionMeta as ActionMeta<MultiselectOption>,
                )
              }
              onKeyDown={multiselectHandleKeyDown}
              value={value}
              readOnly={readOnly}
              ariaLiveMessages={
                readOnly
                  ? {
                      guidance: () => {
                        return 'This field is read only';
                      },
                    }
                  : undefined
              }
              zIndex={zIndex}
            />
          )}
        </Wrapper>

        {!!errorText && <Error>{errorText}</Error>}
      </FormComponentLayout>
    );
  },
);

MDSMultiselect.displayName = 'MDSMultiselect';
