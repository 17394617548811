export const ELLIPSIS = -1;

const generatePagesOnlyRightDotsVisible = (
  currentPage: number,
  totalPages: number,
  paginationSize: number,
) => {
  const pagesToShow: number[] = [];
  pagesToShow.push(
    ...Array.from({ length: currentPage }, (_, page) => page + 1),
  );

  pagesToShow.push(currentPage + 1);
  for (let page = currentPage + 2; page <= paginationSize; page++) {
    pagesToShow.push(page);
  }
  pagesToShow.push(ELLIPSIS);
  const endingPages = [];

  if (currentPage < paginationSize) {
    for (let page = 0; page < paginationSize; page++) {
      endingPages.push(totalPages - page);
    }
  }

  if (currentPage === paginationSize) {
    for (let page = 0; page < paginationSize - 1; page++) {
      endingPages.push(totalPages - page);
    }
  }

  pagesToShow.push(...endingPages.reverse());
  return pagesToShow;
};

const generatePagesOnlyLeftDotsVisible = (
  currentPage: number,
  totalPages: number,
  paginationSize: number,
) => {
  const MAX_PAGES_WITHOUT_ELLIPSIS = paginationSize + 1;
  const pagesToShow: number[] = [];
  if (currentPage > totalPages - paginationSize) {
    for (let page = 1; page < paginationSize; page++) {
      pagesToShow.push(page);
    }
  }

  pagesToShow.push(ELLIPSIS);
  pagesToShow.push(
    ...Array.from(
      { length: MAX_PAGES_WITHOUT_ELLIPSIS - 1 },
      (_, page) => totalPages - (page + 1),
    ).reverse(),
  );
  pagesToShow.push(totalPages);

  return pagesToShow;
};

const generatePagesLeftRightDotsVisible = (
  currentPage: number,
  totalPages: number,
  paginationSize: number,
) => {
  const MAX_PAGES_WITH_LEFT_ELLIPSIS = paginationSize - 1;
  const MAX_PAGES_WITH_RIGHT_ELLIPSIS = paginationSize - 1;
  const pagesToShow: number[] = [];
  pagesToShow.push(1);
  pagesToShow.push(ELLIPSIS);
  pagesToShow.push(
    ...Array.from(
      { length: MAX_PAGES_WITH_RIGHT_ELLIPSIS },
      (_, page) => currentPage - MAX_PAGES_WITH_RIGHT_ELLIPSIS + page + 1,
    ),
  );
  pagesToShow.push(
    ...Array.from(
      { length: MAX_PAGES_WITH_LEFT_ELLIPSIS - 1 },
      (_, page) => currentPage + page + 1,
    ),
  );
  pagesToShow.push(ELLIPSIS);
  pagesToShow.push(totalPages);

  return pagesToShow;
};

/**
 *
 * @param currentSelectedPage
 * @param totalPages
 * @param paginationSize - this accepts 2 | 3 | 4
 * @returns An array of the pages that should be displayed. -1 is being used to indicate an ellipsis
 */
export const generatePagination = (
  currentSelectedPage: number,
  totalPages: number,
  paginationSize: number,
) => {
  const MAX_PAGES_WITHOUT_ELLIPSIS = paginationSize + 1;
  const MAX_PAGES_WITH_RIGHT_ELLIPSIS = paginationSize - 1;
  const MAX_PAGES_IN_VIEW = paginationSize * 2 + 1;
  const currentPage = currentSelectedPage + 1;
  const pagesToShow: number[] = [];

  if (totalPages <= 1) {
    return [];
  }

  if (totalPages <= MAX_PAGES_IN_VIEW) {
    return Array.from({ length: totalPages }, (_, page) => page + 1);
  }

  if (currentPage < MAX_PAGES_WITHOUT_ELLIPSIS) {
    pagesToShow.push(
      ...generatePagesOnlyRightDotsVisible(
        currentPage,
        totalPages,
        paginationSize,
      ),
    );
  } else if (currentPage > totalPages - MAX_PAGES_WITH_RIGHT_ELLIPSIS) {
    pagesToShow.push(
      ...generatePagesOnlyLeftDotsVisible(
        currentPage,
        totalPages,
        paginationSize,
      ),
    );
  } else {
    pagesToShow.push(
      ...generatePagesLeftRightDotsVisible(
        currentPage,
        totalPages,
        paginationSize,
      ),
    );
  }

  return pagesToShow;
};
