import { ChevronRight as ChevronRightIcon, MDSText } from '@midwest/web/base';
import { MDSAnchorButton } from '@midwest/web/button';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;

  border: solid ${(p) => p.theme.midwestColors.semanticColorSentimentInfoWeak}
    1px;
  border-radius: 8px;
  padding: 2rem 1.5rem;

  background-color: ${(p) => p.theme.midwestColors.white};

  ${tablet}, ${desktop} {
    flex-direction: row;
  }
`;
export const ProfileBio = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Info = styled(MDSText.BodyRegSm)`
  line-height: 1.25rem;
`;
export const Link = styled.a`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(p) => p.theme.midwestColors.black};
  display: inline-flex;
  white-space: nowrap;
`;

export const ChevronRight = styled(ChevronRightIcon)`
  fill: ${(p) => p.theme.midwestColors.black};
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;

export const ProfileSpecialties = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  ${tablet}, ${desktop} {
    padding-left: 1.5rem;

    ${({ border, theme }) =>
      border &&
      `
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: ${theme.midwestColors.semanticColorSentimentInfoWeak};
      }
    `}
  }
`;

export const Specialties = styled.ul`
  padding: 0 0 0 1.2rem;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0;

  @media screen and (max-width: 430px) {
    display: auto;
    grid-template-columns: none;
  }

  ${tablet}, ${desktop} {
    display: auto;
    grid-template-columns: none;
  }
`;
export const Specialty = styled.li`
  margin: 0.5rem 0 0 0;
  white-space: nowrap;
`;
export const SpecialtyText = styled(MDSText.BodyRegSm)``;

export const ProfileCta = styled(MDSText.BodyRegSm)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin-top: 1rem;

  ${tablet}, ${desktop} {
    margin-top: 0;
  }
`;

export const Button = styled(MDSAnchorButton)`
  min-width: 10rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;
