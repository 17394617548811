import IconCard, { IconCardProps } from '../icon-card/icon-card';
import { ConnectSectionStyles } from './connect-section.style';
import { Fragment } from 'react';
import { useTheme } from 'styled-components';

const { Section, Container, Title, CTAsContainer, Divider, BoxContainer } =
  ConnectSectionStyles;

export interface ConnectSectionProps {
  headline?: string;
  callToAction?: IconCardProps[];
  backgroundColor?: string;
  id?: string;
  showNewButtonColor?: boolean;
}

/** @deprecated chances are if your using this you ACTUALLY want ConnectionOptionsSection */
const ConnectSection = ({
  headline,
  callToAction,
  backgroundColor: bgColor,
  id,
  showNewButtonColor = false,
}: ConnectSectionProps) => {
  const { midwestColors: colors } = useTheme();
  const backgroundColor = bgColor || colors.identityBrandTertiaryFaint;
  return (
    <Section id={id} backgroundColor={backgroundColor}>
      <Container>
        <Title as="h2">{headline}</Title>
        <CTAsContainer>
          {callToAction &&
            callToAction.map((cta, index) => (
              <Fragment key={cta.icon}>
                <BoxContainer>
                  <IconCard
                    {...cta}
                    showDarkerBlueButton={showNewButtonColor}
                  />
                </BoxContainer>
                {index < callToAction.length - 1 && <Divider />}
              </Fragment>
            ))}
        </CTAsContainer>
      </Container>
    </Section>
  );
};

export default ConnectSection;
