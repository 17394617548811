import { MDSText } from '@midwest/web/base';
import { desktop } from '@thrivent/midwest-shared';
import NextImage from 'next/image';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  justify-content: flex-start;
  ${desktop} {
    max-width: 46rem;
  }
`;

export const Controls = styled.div`
  font-weight: 700;
  font-size: 1.2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: solid ${(p) => p.theme.midwestColors.semanticColorSentimentInfoWeak}
    1px;
  border-radius: 8px;
  padding: 5rem;
  background-color: ${(p) => p.theme.midwestColors.white};
`;

export const Title = styled.div``;

export const Count = styled.span`
  font-weight: 400;
`;

export const ResultsTitle = styled(MDSText.TitleMd)`
  padding-top: 1.5rem;
`;

export const ResultsText = styled.span`
  padding-top: 0.5rem;
`;
export const Sort = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SortSelect = styled.select`
  background-color: inherit;
  padding: 0 0 0 0.5rem;
`;

export const Image = styled(NextImage)`
  object-fit: ${(p) => (p.objectFit ? p.objectFit : 'cover')};
`;

export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none;
`;
