'use client';

import {
  CheckboxButtonIndicator,
  CheckboxButtonItem,
  CheckboxButtonRoot,
  CheckboxButtonTextHeavy,
  CheckboxButtonTextMedium,
  CheckboxIndicatorWrapper,
} from './checkboxStyles';
import { CheckboxButtonProps } from './types';
import {
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxUncheckedBasicHoverIcon,
  CheckboxUncheckedIcon,
  ErrorIconAndText,
} from '@midwest/web/shared';
import { forwardRef, useId, useState } from 'react';

/**
 ```jsx
 import { MDSCheckboxButton } from '@midwest/web/forms';
 ```
 * Checkboxes are most commonly used to give users a way to make a range of selections. They may also be used as a way to have users agree to specific terms and services.
 */
export const MDSCheckboxButton = forwardRef<
  HTMLButtonElement,
  CheckboxButtonProps
>(
  (
    {
      name,
      label,
      value,
      checked,
      disabled,
      hideIndicator = false,
      invalid,
      onChange,
      basic,
      errorText,
      dataTestId,
      groupErrorId = '',
      helperTextId = '',
      groupLabelId = '',
      buttonId,
      onButtonFocus,
      ariaLabel,
      mediumFontLabel = false,
    },
    ref,
  ) => {
    const errorId = useId();
    const labelId = useId();
    const fallbackButtonId = useId();

    const isErrorVisible = !!invalid && !!errorText;

    /**
     * see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby
     * see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
     *
     * space separated list of IDs that screen readers will concatenate in the order specified and read out
     */
    const describedByIds = [helperTextId, groupErrorId];
    if (isErrorVisible) {
      describedByIds.push(errorId);
    }
    const describedById = describedByIds.filter(Boolean).join(' ') || undefined;
    const labeledByIds = [groupLabelId, labelId];
    const labeledById = labeledByIds.filter(Boolean).join(' ');

    const [basicHover, setBasicHover] = useState(false);

    const handleMouseEnter = () => {
      if (basic) setBasicHover(true);
    };

    const handleMouseLeave = () => {
      setBasicHover(false);
    };

    const CheckboxButtonText = mediumFontLabel
      ? CheckboxButtonTextMedium
      : CheckboxButtonTextHeavy;

    return (
      <CheckboxButtonRoot>
        <CheckboxButtonItem
          name={name}
          role="checkbox"
          value={value}
          disabled={disabled}
          $disabled={disabled}
          $invalid={invalid}
          data-testid={dataTestId ?? 'indicator'}
          onCheckedChange={onChange}
          checked={checked}
          aria-checked={checked === 'indeterminate' ? 'mixed' : checked}
          $basic={basic}
          ref={ref}
          aria-describedby={describedById}
          aria-labelledby={labeledById}
          id={buttonId || fallbackButtonId}
          onFocus={onButtonFocus}
          aria-label={ariaLabel}
          className="checkbox-button-action-bar"
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        >
          {hideIndicator ? (
            <span></span>
          ) : (
            <CheckboxIndicatorWrapper className="CheckboxRoot" id={name}>
              {checked === 'indeterminate' ? (
                <CheckboxIndeterminateIcon
                  name={name}
                  $disabled={disabled}
                  $basicHover={basicHover}
                />
              ) : checked ? (
                <CheckboxButtonIndicator className="CheckboxIndicator">
                  <CheckboxCheckedIcon
                    name={name}
                    $disabled={disabled}
                    $basicHover={basicHover}
                  />
                </CheckboxButtonIndicator>
              ) : basicHover ? (
                <CheckboxUncheckedBasicHoverIcon
                  name={name}
                  $disabled={disabled}
                />
              ) : (
                <CheckboxUncheckedIcon name={name} $disabled={disabled} />
              )}
            </CheckboxIndicatorWrapper>
          )}
          {!!label && (
            <CheckboxButtonText
              id={labelId}
              data-testid={'checkbox-label'}
              $disabled={disabled}
              htmlFor={buttonId || fallbackButtonId}
            >
              {label}
            </CheckboxButtonText>
          )}
        </CheckboxButtonItem>

        {isErrorVisible && (
          <ErrorIconAndText errorId={errorId} errorText={errorText} />
        )}
      </CheckboxButtonRoot>
    );
  },
);

MDSCheckboxButton.displayName = 'MDSCheckboxButton';
