import {
  IconCard,
  SectionContainer,
  SectionRoot,
} from '@exploration/shared-components';
import { MDSButton } from '@midwest/web/button';
import { Badge } from '@midwest/web/indicators';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled, { DefaultTheme } from 'styled-components';

// --------------------------------------------------------------------------

export const CTAConnectionOptionsStyles = {
  Section: styled(SectionRoot)<{
    backgroundColor?: string;
  }>`
    background-color: ${(props) => props.backgroundColor};
  `,
  Container: styled(SectionContainer)<{ forceMobileBreakpoint?: boolean }>`
    ${({ forceMobileBreakpoint }) => `
      padding-block: 3rem;

      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      ${
        !forceMobileBreakpoint &&
        `
        ${desktop} {
          padding-block: 4rem 7.5rem;
          gap: 2.5rem;
        }
      `
      }
    `}
  `,
  Title: styled(MDSText.HeroMd)`
    text-align: center;
  `,
  CTAsContainer: styled.div<{ forceMobileBreakpoint?: boolean }>`
    ${({ forceMobileBreakpoint }) => `
      display: flex;
      flex-direction: column;
      gap: 2rem;

      ${
        !forceMobileBreakpoint &&
        `
        ${tablet} {
          align-items: center;
        }
        ${desktop} {
          align-items: initial;
          flex-direction: row;
          justify-content: space-between;
        }
        `
      }
    `}
  `,
  BoxContainer: styled.div<{ forceMobileBreakpoint?: boolean }>`
    ${({ forceMobileBreakpoint }) => `
      width: 100%;

      ${
        !forceMobileBreakpoint &&
        `
        ${tablet} {
          max-width: 30.25rem;
        }
      `
      }
    `}
  `,
  IconCard: styled(IconCard)`
    height: 100%;
  `,
  Badge: styled(Badge)`
    margin-left: 0.25rem;
  `,
  LiveChatButton: styled(MDSButton)<{
    forceMobileBreakpoint?: boolean;
    showDarkerBlueButton: boolean;
    theme: DefaultTheme;
  }>`
    ${({ showDarkerBlueButton, theme }) => `
      ${
        !showDarkerBlueButton &&
        `
        background-color: ${theme.midwestColors.componentButtonColorBorderSubtlePrimaryDefault};
        border-color: ${theme.midwestColors.componentButtonColorBorderSubtlePrimaryDefault};
      `
      }
    `}
    ${({ forceMobileBreakpoint }) => `
      white-space: nowrap;
      width: 100%;

      ${
        !forceMobileBreakpoint &&
        `
        ${tablet} {
          width: fit-content;
        }
      `
      }
    `}
  `,
};
