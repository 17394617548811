import { MDSText } from '@midwest/web/base';
import { MDSMultiselect, MDSSelect } from '@midwest/web/forms';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${desktop} {
    max-width: 24rem;
  }
`;

export const RefineTitle = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
`;

export const FilterContainer = styled.div`
  border: solid ${(p) => p.theme.midwestColors.semanticColorSentimentInfoWeak}
    1px;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.midwestColors.white};
  gap: 1.5rem;

  ${tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    align-items: start;
  }

  ${desktop} {
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
    grid-column: auto;
  }
`;

export const Multiselect = styled(MDSMultiselect)`
  flex: 1;
  min-width: 16rem;
  ${tablet} {
    grid-column: span 3;
  }
`;
export const Select = styled(MDSSelect)`
  flex: 1;
  min-width: 16rem;
  ${desktop} {
    grid-column: auto;
    width: 100%;
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-width: 16rem;
  ${desktop} {
    grid-column: auto;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0;
  flex-shrink: 0;
  > button {
    width: 100%;
  }
  ${tablet} {
    justify-content: flex-end;
    > button {
      margin-top: 0.5rem;
      max-width: 10rem;
    }
    margin-top: 1rem;
  }

  ${desktop} {
    margin-top: 0;
    > button {
      width: auto;
    }
  }
`;

export const LocationText = styled(MDSText.BodyHeavySm)`
  padding-bottom: 0.25rem;
`;
export const FilterSearchWrapper = styled.div`
  position: relative;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  flex-shrink: 0;
  input {
    width: 100%;
    border: solid #c7c7ca 2px;
    border-radius: 4px;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    box-sizing: border-box;
    &:hover,
    &:focus {
      border-color: #141414;
    }
  }

  .sr-only,
  .hidden {
    display: none;
  }

  .absolute {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 100;

    & > div {
      border: solid #c7c7ca 2px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0.25rem;

      div[id] {
        padding: 0.25rem;
      }
    }
  }
`;
