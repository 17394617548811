'use client';

import { ResultDialog } from './ResultDialog';
import { SearchAdvisorBox } from './SearchAdvisorBox';
import { SearchAdvisorClassic } from './SearchAdvisorClassic';
import { SearchAdvisorDropdown } from './SearchAdvisorDropdown';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

export const SearchAdvisor = ({
  dropdown,
  dropdownPlaceholderText,
  buttonOnly,
  interactiveSearch,
  minimal,
  ctaText,
  id,
  showChevron,
}: {
  dropdown?: boolean;
  dropdownPlaceholderText?: string;
  buttonOnly?: boolean;
  interactiveSearch?: boolean;
  minimal?: boolean;
  ctaText?: string;
  id?: string;
  showChevron?: boolean;
}) => {
  const [showResults, setModalOpen] = useState(false);
  const flags = useAtomValue(explorationFeatureFlagAtom);
  const isAdvisorModalEnabled = flags['enable-advisor-modal'];

  const handleSearch = () => {
    setModalOpen(!showResults);
  };

  useEffect(() => {
    if (showResults) {
      document.body.classList.add('search-advisor-modal-open');
    } else {
      document.body.classList.remove('search-advisor-modal-open');
    }
  }, [showResults]);

  if (!isAdvisorModalEnabled) {
    return (
      <SearchAdvisorClassic
        interactiveSearch={interactiveSearch}
        minimal={minimal}
        ctaText={ctaText}
        buttonOnly={buttonOnly}
        id={id}
        showChevron={showChevron}
      />
    );
  }
  return (
    <>
      {dropdown ? (
        <SearchAdvisorDropdown
          onResult={handleSearch}
          interactiveSearch={interactiveSearch}
          minimal={minimal}
          ctaText={ctaText}
          dropdownPlaceholderText={dropdownPlaceholderText}
          buttonOnly={buttonOnly}
          id={id}
          showChevron={showChevron}
        />
      ) : (
        <SearchAdvisorBox onResult={handleSearch} />
      )}
      <ResultDialog open={showResults} onOpenChange={setModalOpen} />
    </>
  );
};
