import { VirtualAssistantChatAtom, VirtualAssistantChatWindow } from '../types';
import { logger } from '@thrivent-web/logging-utils';

// --------------------------------------------------------------------------

/**
 * launchVirtualAssistantChat
 *
 * Allows chat window to pop up when called
 * @param function - setVirtualAssistantChat jotai setter
 * @returns Promise<boolean>
 */
export const launchVirtualAssistantChat = async (
  setVirtualAssistantChat: (
    callback: (prev: VirtualAssistantChatAtom) => VirtualAssistantChatAtom,
  ) => void,
) => {
  try {
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isLoading: true,
    }));
    const virtualAssistantChatWindow = window as VirtualAssistantChatWindow;
    if (virtualAssistantChatWindow?.embedded_svc?.bootstrapEmbeddedService) {
      const result =
        await virtualAssistantChatWindow.embedded_svc.bootstrapEmbeddedService();
      setVirtualAssistantChat((prev) => ({
        ...prev,
        isLoading: false,
        isActive: true,
      }));
      return Promise.resolve(result);
    } else {
      logger.error('Unable to bootstrap Virtual Assistant Chat', null);
      setVirtualAssistantChat((prev) => ({
        ...prev,
        isLoading: false,
        hasError: true,
      }));
      return Promise.resolve(false);
    }
  } catch (err) {
    logger.error('Error Bootstrapping Virtual Assistant Chat: ', err);
    setVirtualAssistantChat((prev) => ({
      ...prev,
      isLoading: false,
      hasError: true,
    }));
    return Promise.resolve(false);
  }
};
