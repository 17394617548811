'use client';

import { filterTitles } from '../util/filterMappings';
import {
  Button,
  Chevron,
  Container,
  Dropdown,
  StyledContainer,
} from './SearchAdvisorDropdown.styles';
import { useSearch } from './useSearch';

type Props = {
  onResult: () => void;
  interactiveSearch?: boolean;
  minimal?: boolean;
  ctaText?: string;
  dropdownPlaceholderText?: string;
  buttonOnly?: boolean;
  id?: string;
  showChevron?: boolean;
};

export const SearchAdvisorDropdown = ({
  onResult,
  interactiveSearch,
  minimal,
  ctaText,
  dropdownPlaceholderText,
  buttonOnly = false,
  id,
  showChevron = false,
}: Props) => {
  const { lifeStages, handleSpecialtyFilter, handleSearch } = useSearch();

  return buttonOnly ? (
    <Button
      data-analytics-id={id}
      interactiveSearch={interactiveSearch}
      buttonOnly={buttonOnly}
      showChevron={showChevron}
      onClick={() => {
        handleSearch();
        onResult();
      }}
    >
      {ctaText || 'Find financial advisors'} {showChevron && <Chevron />}
    </Button>
  ) : (
    <Container interactiveSearch={interactiveSearch} minimal={minimal}>
      <StyledContainer interactiveSearch={interactiveSearch} minimal={minimal}>
        <Dropdown
          name="financial goals dropdown"
          label="Financial goals"
          placeholder={
            dropdownPlaceholderText ? dropdownPlaceholderText : 'Select topics'
          }
          options={filterTitles}
          value={lifeStages}
          onChange={handleSpecialtyFilter}
          minimal={minimal}
        />
        <Button
          data-analytics-id={id}
          weight={minimal ? 'subtle' : 'strong'}
          interactiveSearch={interactiveSearch}
          minimal={minimal}
          onClick={() => {
            handleSearch();
            onResult();
          }}
        >
          {ctaText || 'Find financial advisors'}
        </Button>
      </StyledContainer>
    </Container>
  );
};
