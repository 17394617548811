import { MDSCheckboxButton } from '../Forms/Checkbox/CheckboxButton';
import {
  AddIcon,
  AddNewOption,
  CheckboxFocusOutline,
  IconCreateContainer,
  MultiselectDropdownOption,
  MultiselectMultiValueAdditional,
  MultiselectMultiValueLabel,
  MultiselectMultiValueRemove,
  MultiselectOptionLabelText,
} from '../Forms/Multiselect/Multiselect.styles';
import { MultiselectWrapperProps } from '../Forms/Multiselect/types';
import { SelectWrapperProps } from '../Forms/Select/types';
import {
  AsyncTypeaheadWrapperProps,
  TypeaheadWrapperProps,
} from '../Forms/Typeahead/types';
import {
  Alert,
  ArrowUnfoldMoreIcon,
  BadgeContainer,
  CustomContainerStyles,
  LoadingMessageContainer,
  NoOptions,
  Placeholder,
  ReadOnlyInputDisplay,
  SelectItem,
  StyledCustomValueContainer,
  StyledMultiValueRemoveContainer,
  SuffixPrefix,
} from './StyledComponents';
import { Ellipse, MDSText } from '@midwest/web/base';
import { forwardRef, useContext } from 'react';
import {
  MultiValueGenericProps,
  MultiValueRemoveProps,
  default as Select,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { ThemeContext } from 'styled-components';

/* -------------------------------------------------------------------------------------------------
 * Shared Custom Components
 * -----------------------------------------------------------------------------------------------*/

export const useTheme = () => {
  return useContext(ThemeContext);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomIndicatorSeparator = (props: any) => {
  return (
    <div style={{ paddingTop: 5 }} {...props.innerProps}>
      {props.selectProps['aria-invalid'] && <Alert />}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SuffixCustomIndicatorSeparator = (props: any) => {
  return (
    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
      {props.selectProps.suffix && (
        <SuffixPrefix style={{ margin: 'auto', right: '0' }}>
          {props.selectProps.suffix}
        </SuffixPrefix>
      )}
      <div style={{ paddingTop: 5 }} {...props.innerProps}>
        {props.selectProps['aria-invalid'] && <Alert />}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomValueContainer = (props: any) => {
  return (
    <StyledCustomValueContainer {...props.innerProps}>
      {props.selectProps.prefix && (
        <SuffixPrefix>{props.selectProps.prefix}</SuffixPrefix>
      )}
      {props.children}
    </StyledCustomValueContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomDropdownIndicator = (props: any) => {
  return <ArrowUnfoldMoreIcon disabled={props.selectProps.isDisabled} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomPlaceholder = (props: any) => {
  return (
    <Placeholder disabled={props.isDisabled}>
      {props.selectProps.placeholder}
    </Placeholder>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomNoOptionsMessage = (props: any) => {
  return (
    <div {...props.innerProps}>
      <NoOptions $focused={props.isFocused}>
        <MDSText.BodyRegSm as={'span'}>No Options</MDSText.BodyRegSm>
      </NoOptions>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomContainer = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <CustomContainerStyles
        $isClicked={props.selectProps.isClicked}
        $menuIsOpen={props.selectProps.menuIsOpen}
      >
        {props.children}
      </CustomContainerStyles>
    </components.SelectContainer>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Select Specific Custom Components
 * -----------------------------------------------------------------------------------------------*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomOption = (props: any) => {
  const theme = useTheme();
  return (
    <div ref={props.innerRef} {...props.innerProps}>
      <SelectItem role="option" $focused={props.isFocused}>
        {props.isSelected ? (
          <Ellipse
            fill={
              props.isFocused
                ? theme.midwestColors.textPrimaryDarkBG
                : theme.midwestColors.textPrimary
            }
          />
        ) : null}
        {props.isSelected ? (
          <MDSText.BodyHeavySm>{props.children}</MDSText.BodyHeavySm>
        ) : (
          <MDSText.BodyRegSm>{props.children}</MDSText.BodyRegSm>
        )}
        {props.data.badge && (
          <BadgeContainer>{props.data.badge}</BadgeContainer>
        )}
      </SelectItem>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomSingleValue = (props: any) => {
  if (props.selectProps.readOnly) {
    return (
      <ReadOnlyInputDisplay
        aria-label={props.selectProps['aria-label']}
        $readOnly={props.selectProps.readOnly}
        $disabled={props.selectProps.isDisabled}
        disabled
        value={props.children}
      />
    );
  }

  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MDSText.TextInterfaceRegularSm
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {props.children}
        </MDSText.TextInterfaceRegularSm>
        {props.data?.badge && (
          <BadgeContainer>{props.data.badge}</BadgeContainer>
        )}
      </div>
    </components.SingleValue>
  );
};

export const CustomSelect = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: SelectWrapperProps, ref: React.Ref<any>) => {
    return <Select {...props} ref={ref} />;
  },
);
CustomSelect.displayName = 'CustomSelect';

/* -------------------------------------------------------------------------------------------------
 * Typeahead Specific Custom Components
 * -----------------------------------------------------------------------------------------------*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTypeaheadOption = (props: any) => {
  const theme = useTheme();
  return (
    <div ref={props.innerRef} {...props.innerProps}>
      <SelectItem role="option" $focused={props.isFocused}>
        {props.isSelected ? (
          <Ellipse
            fill={
              props.isFocused
                ? theme.midwestColors.textPrimaryDarkBG
                : theme.midwestColors.textPrimary
            }
          />
        ) : null}
        {props.isSelected ? (
          <MDSText.BodyHeavySm>{props.children}</MDSText.BodyHeavySm>
        ) : (
          <MDSText.BodyRegSm>{props.children}</MDSText.BodyRegSm>
        )}
      </SelectItem>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomTypeaheadSingleValue = (props: any) => {
  if (props.selectProps.readOnly) {
    return (
      <components.SingleValue {...props}>
        <ReadOnlyInputDisplay
          aria-label={props.selectProps['aria-label']}
          $readOnly={props.selectProps.readOnly}
          $disabled={props.selectProps.isDisabled}
          disabled
          value={props.children}
        />
      </components.SingleValue>
    );
  }

  return (
    <components.SingleValue {...props}>
      <MDSText.TextInterfaceRegularSm>
        {props.children}
      </MDSText.TextInterfaceRegularSm>
    </components.SingleValue>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomLoadingMessage = (props: any) => {
  return (
    <LoadingMessageContainer>
      <MDSText.BodyRegSm>Loading...</MDSText.BodyRegSm>
    </LoadingMessageContainer>
  );
};

export const CustomTypeahead = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: TypeaheadWrapperProps, ref: React.Ref<any>) => {
    return <Select {...props} ref={ref} />;
  },
);
CustomTypeahead.displayName = 'CustomTypeahead';

export const CustomAsyncTypeahead = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: AsyncTypeaheadWrapperProps, ref: React.Ref<any>) => {
    return <AsyncSelect {...props} ref={ref} />;
  },
);
CustomTypeahead.displayName = 'CustomAsyncTypeahead';

/* -------------------------------------------------------------------------------------------------
 * Multiselect Specific Custom Components
 * -----------------------------------------------------------------------------------------------*/

const MAX_SELECTED_VALUES = 1;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export const CustomValueOption = (props: any) => {
  if (props.selectProps.menuIsOpen) {
    return (
      <components.ValueContainer {...props} {...props.innerProps}>
        {props.children}
      </components.ValueContainer>
    );
  } else if (props.selectProps.readOnly) {
    const selectedOptions = props.children[0];

    if (selectedOptions.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const selectedOptionsString = selectedOptions
        .map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (option: { props: any }) => option.props.data.label,
        )
        .join(', ');

      return (
        <div
          style={{ width: '100%', outline: 'none' }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={`read only ${selectedOptionsString}`}
        >
          <ReadOnlyInputDisplay
            aria-label={props.selectProps['aria-label']}
            $readOnly={props.selectProps.readOnly}
            $disabled={props.selectProps.isDisabled}
            disabled
            value={selectedOptionsString}
            style={{ width: '100%' }}
          />
        </div>
      );
    } else {
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      return (
        <div tabIndex={0} style={{ width: '100%', outline: 'none' }}></div>
      );
    }
  } else if (
    props.children[0] &&
    props.children[0].length > MAX_SELECTED_VALUES
  ) {
    return (
      <components.ValueContainer
        {...props}
        {...props.innerProps}
        {...props.selectProps}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
        <MultiselectMultiValueAdditional
          count={props.children[0].length - MAX_SELECTED_VALUES}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          {props.children[0].slice(0, MAX_SELECTED_VALUES)}
        </MultiselectMultiValueAdditional>
        {props.children[1]}
      </components.ValueContainer>
    );
  } else {
    return (
      <components.ValueContainer {...props} {...props.innerProps}>
        {props.children}
      </components.ValueContainer>
    );
  }
};

export const CustomMultiValueLabel = (props: MultiValueGenericProps) => {
  return (
    <MultiselectMultiValueLabel {...props.innerProps}>
      {props.data.label}
    </MultiselectMultiValueLabel>
  );
};

export const CustomMultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <div {...props.innerProps} data-testid="remove">
      <StyledMultiValueRemoveContainer>
        <MultiselectMultiValueRemove />
      </StyledMultiValueRemoveContainer>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MultiselectCustomOption = (props: any) => {
  if (props.data.__isNew__) {
    return (
      <div ref={props.innerRef} {...props.innerProps}>
        <AddNewOption $focused={props.isFocused}>
          <IconCreateContainer>
            <AddIcon />{' '}
            <MultiselectOptionLabelText style={{ marginTop: 3 }}>
              Add {'"'}
              {props.selectProps.inputValue}
              {'"'}
            </MultiselectOptionLabelText>
          </IconCreateContainer>
        </AddNewOption>
      </div>
    );
  }
  return (
    <div ref={props.innerRef} {...props.innerProps}>
      <MultiselectDropdownOption $focused={props.isFocused}>
        <CheckboxFocusOutline>
          <MDSCheckboxButton
            name={props.value}
            value={props.value}
            checked={props.isSelected}
            label={props.label}
            basic={true}
          />
        </CheckboxFocusOutline>
      </MultiselectDropdownOption>
    </div>
  );
};

export const CustomCreatableSelect = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: MultiselectWrapperProps, ref: React.Ref<any>) => {
    return <CreatableSelect {...props} ref={ref} />;
  },
);
CustomCreatableSelect.displayName = 'CustomCreatableSelect';

export const CustomMultiSelect = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: MultiselectWrapperProps, ref: React.Ref<any>) => {
    return <Select {...props} ref={ref} />;
  },
);
CustomMultiSelect.displayName = 'CustomMultiSelect';
