import { SectionContainer, SectionRoot } from '@exploration/shared-components';
import { MDSAnchorButton } from '@midwest/web/button';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const QuickConnectSectionRoot = styled(SectionRoot)`
  background-image: radial-gradient(#363636 0%, #363636 100%);
`;

export const QuickConnectSectionContainer = styled(SectionContainer)`
  justify-content: center;
  padding-inline: 1.25rem;
`;

export const QuickConnectLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.midwestColors.white};
  max-width: 44rem;
  gap: 1rem;
  padding-block: 4rem;
`;

export const QuickConnectTitle = styled(MDSText.HeroMd)`
  ${desktop} {
    font-size: 2.5rem;
  }
`;

export const QuickConnectDescription = styled(MDSText.BodyRegDefault)`
  font-size: 1rem;
  line-height: 1.25rem;
  ${tablet} {
    font-weight: 500;
    line-height: 1.75rem;
    font-size: 1.25rem;
    max-width: 36rem;
  }
`;

export const QuickConnectBody = styled(MDSText.Subtitle)``;

export const QuickConnectButton = styled(MDSAnchorButton)`
  margin-top: 1rem;
  background-color: ${({ theme }) =>
    theme.midwestColors.componentButtonColorBackgroundStrongSecondaryDefault};
  color: ${({ theme }) =>
    theme.midwestColors.componentButtonColorTextSubtlePrimary};
  outline: none;
`;
