export const PaginationSizes = {
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
} as const;

export type PaginationProps = {
  onPageChange: (page: number) => unknown;
  paginationSize: (typeof PaginationSizes)[keyof typeof PaginationSizes]; // also update size to choose
  totalNumberOfItems: number;
  itemCount: number;
  currentPage: number;
};
