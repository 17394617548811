import { Refine } from './Refine';
import {
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogInfo,
  DialogOverlay,
  DialogRoot,
  DialogTitle,
} from './ResultDialog.style';
import { Results } from './Results';
import * as Dialog from '@radix-ui/react-dialog';
import { Close } from '@thrivent/midwest-web';
import { Dispatch, SetStateAction } from 'react';

const preventDefault = (
  event: { preventDefault: () => void } | KeyboardEvent,
) => {
  event.preventDefault();
};

export type ResultDialogProps = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export const ResultDialog = ({ open, onOpenChange }: ResultDialogProps) => {
  return (
    <DialogRoot open={open} modal={true} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent
          data-analytics-name="rep-search-modal"
          onInteractOutside={preventDefault}
        >
          <DialogHeader>
            <DialogTitle>Search results</DialogTitle>
            <DialogClose
              data-analytics-id="close-find-advisor-modal"
              aria-label="Close Find Advisor Modal"
            >
              <Close fill="#19398C" />
            </DialogClose>
          </DialogHeader>
          <DialogInfo>
            <Refine />
            <Results />
          </DialogInfo>
        </DialogContent>
      </Dialog.Portal>
    </DialogRoot>
  );
};
