import { CheckboxProps, MDSText, ValidationProps } from '@midwest/web/base';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled, { css } from 'styled-components';

export const CheckboxButtonRoot = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const CheckboxButtonGroupWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export const CheckboxButtonLabel = styled.label<ValidationProps>`
  all: unset;
  display: flex;
  box-sizing: border-box;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  border: 2px solid
    ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.textSecondaryDarkBG
        : p.theme.midwestColors.neutralDarkWeak};
  position: relative;
`;

const CheckboxTextStyles = css<ValidationProps>`
  white-space: pre-wrap;
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
  text-align: start;
  ${(p) =>
    p.$disabled &&
    css`
      color: ${(p) => p.theme.midwestColors.textDisabled};
      cursor: not-allowed;
    `}
`;

export const CheckboxButtonTextHeavy = styled(
  MDSText.BodyHeavySm,
)<ValidationProps>`
  ${CheckboxTextStyles}
`;

export const CheckboxButtonTextMedium = styled(
  MDSText.BodyMediumSm,
)<ValidationProps>`
  ${CheckboxTextStyles}
`;

export const CheckboxButtonItem = styled(Checkbox.Root)<CheckboxProps>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.xxsm};
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
  outline: ${(p) => p.theme.border.widthDefault} solid
    ${(p) =>
      p.$basic
        ? 'transparent'
        : p.$disabled
          ? p.theme.midwestColors.borderDisabled
          : p.theme.midwestColors.neutralLightHeavy};
  border-radius: ${(p) => p.theme.radius.buttonRadius};
  border: 0;
  padding: ${(p) =>
    p.$basic ? `${p.theme.spacing.xxxsm}` : `${p.theme.spacing.sm}`};
  position: relative;
  background-color: ${(p) =>
    p.$basic ? 'transparent' : p.theme.midwestColors.neutralLightMedium};
  color: ${(p) =>
    p.$invalid
      ? p.theme.midwestColors.sentimentNegativeDense
      : p.$disabled
        ? p.theme.midwestColors.textSecondaryDarkBG
        : p.theme.midwestColors.textSecondary};

  &:focus-visible {
    transition: none;
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: 100%;
      height: 100%;
      border-radius: ${(p) =>
        p.$basic ? p.theme.radius.radiusMedium : p.theme.radius.radiusLarge};
      padding: 3px;
      box-shadow: 0px 0px 0px ${(p) => p.theme.border.widthFocused}
        ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
      background-color: transparent;
    }
  }
  ${(p) =>
    !p.$disabled &&
    !p.$basic &&
    css`
      /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
      &:hover,
      &[data-state='checked']:hover,
      &[data-state='indeterminate']:hover {
        color: ${!p.$basic &&
        (p.theme.midwestColors.identityBrandPrimaryHeavy as string)};
        outline: ${p.theme.border.widthEmphasised as string} solid
          ${p.theme.midwestColors.identityBrandPrimaryMedium as string};
        background-color: ${p.theme.midwestColors
          .identityBrandPrimaryWeak as string};
      }
    `}

  &:hover {
    color: ${(p) => (p.$disabled ? '' : p.theme.midwestColors.textPrimary)};
    outline: ${(p) => p.theme.border.widthDefault} solid
      ${(p) => {
        if (p.$disabled) {
          return p.theme.midwestColors.borderDisabled;
        } else if (p.$basic) {
          return 'transparent';
        } else {
          return p.theme.midwestColors.neutralDarkHeavy;
        }
      }};
    background-color: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.backgroundDisabled
        : p.$basic
          ? 'transparent'
          : p.theme.midwestColors.neutralLightStrong};
  }

  &[data-state='checked'],
  &[data-state='indeterminate'] {
    color: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.neutralLightStrong
        : p.$basic
          ? p.theme.midwestColors.identityBrandPrimaryStrong
          : p.theme.midwestColors.identityBrandPrimaryHeavy};
    outline: ${(p) => p.theme.border.widthEmphasised} solid
      ${(p) =>
        p.$disabled
          ? p.theme.midwestColors.borderDisabled
          : p.$basic
            ? 'transparent'
            : p.theme.midwestColors.identityBrandPrimaryMedium};
    /* stylelint-disable annotation-no-unknown -- Disabled during migration to Stylelint 15.  Please Fix.*/
    box-shadow: ${(p) =>
      !p.$disabled && !p.$basic
        ? '0px 3px 6px 0px #254BB280 inset'
        : ' 0px 3px 6px 0px transparent inset'};
    /* stylelint-enable annotation-no-unknown -- Disabled during migration to Stylelint 15.  Please Fix.*/
    &:focus,
    &:hover {
      outline: ${(p) => p.theme.border.widthEmphasised} solid
        ${(p) =>
          p.$basic
            ? 'transparent'
            : p.$disabled
              ? p.theme.midwestColors.borderDisabled
              : p.theme.midwestColors.identityBrandPrimaryMedium};
    }
    background-color: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.backgroundDisabled
        : p.$basic
          ? 'transparent'
          : p.theme.midwestColors.identityBrandPrimaryFaint};

    ${CheckboxButtonTextMedium}, ${CheckboxButtonTextHeavy} {
      color: ${(p) =>
        p.$disabled
          ? p.theme.midwestColors.textDisabled
          : p.$basic
            ? p.theme.midwestColors.neutralDarkStrong
            : p.theme.midwestColors.identityBrandPrimaryHeavy};
    }

    ${CheckboxButtonLabel} {
      border: ${(p) => p.theme.border.widthEmphasised} solid transparent;
    }
  }

  ${(p) =>
    p.$invalid &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        border-radius: ${p.$basic
          ? (p.theme.radius.radiusSmall as string)
          : '7px'};
        padding: 1px;
        box-shadow: 0px 0px 0px ${p.theme.border.widthDefault as string}
          ${p.theme.midwestColors.sentimentNegativeStrong as string};
      }
    `}

  ${(p) =>
    p.$basic &&
    css`
      outline: none !important;
      background: transparent !important;
    `}

  @media (prefers-reduced-motion: no-preference) {
    transition: all 0.2s ease;
  }
`;

export const CheckboxIndicatorWrapper = styled.div<CheckboxProps>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const CheckboxButtonIndicator = styled(Checkbox.Indicator)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const HelperText = styled(MDSText.BodyMediumSm)<CheckboxProps>`
  color: ${(p) => p.theme.midwestColors.textSecondary};
  white-space: pre-wrap;
`;

export const LabelText = styled(MDSText.BodyHeavySm)<CheckboxProps>`
  color: ${(p) => p.theme.midwestColors.textSecondary};
  white-space: pre-wrap;
`;
