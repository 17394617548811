import { designationToSpecialtyMapping } from '../util';
import { Advisor } from '../util/advisorSearch';
import {
  Button,
  ChevronRight,
  Container,
  Info,
  Link,
  ProfileBio,
  ProfileCta,
  ProfileSpecialties,
  Specialties,
  Specialty,
  SpecialtyText,
} from './Profile.style';

type ResultProps = {
  advisor: Advisor;
};
export const Profile = ({ advisor }: ResultProps) => {
  const renderedSpecialties = new Set();
  const hasDesignations = !!advisor.designations;

  return (
    <Container>
      <ProfileBio>
        <Link target="_blank" href={advisor.website}>
          {advisor.fPName || advisor.name.replace(/\s-\sThrivent$/, '')}
          <ChevronRight />
        </Link>
        <Info>
          {advisor.title}
          {hasDesignations && (
            <>
              <br />
              {advisor.designations.join(', ')}
            </>
          )}
          <br />
          {advisor.address.city}, {advisor.address.region}{' '}
          {advisor.address.postalCode}
        </Info>
      </ProfileBio>
      <ProfileSpecialties border={hasDesignations}>
        {hasDesignations ? (
          <>
            <h4>Specialties:</h4>
            <Specialties>
              {advisor.designations.map((designation: string) => {
                const specialties = designationToSpecialtyMapping[designation];
                return specialties?.map((specialty) => {
                  if (!renderedSpecialties.has(specialty)) {
                    renderedSpecialties.add(specialty);
                    return (
                      <Specialty key={specialty}>
                        <SpecialtyText>{specialty}</SpecialtyText>
                      </Specialty>
                    );
                  }
                  return null;
                });
              })}
            </Specialties>
          </>
        ) : null}
      </ProfileSpecialties>
      <ProfileCta>
        <Button href={`tel:${advisor.phone}`} target="_blank">
          {advisor.phone.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3')}
        </Button>
      </ProfileCta>
    </Container>
  );
};
