import { liveChatAtom } from './live-chat-atom';
import { useAuth } from '@thrivent-web/authentication';
import { useAtom } from 'jotai';

// --------------------------------------------------------------------------

export const useLiveChat = () => {
  const [liveChat, setLiveChat] = useAtom(liveChatAtom);
  const { isAuthenticated } = useAuth();
  const {
    isActiveBusinessHours,
    isInitialized,
    hasError,
    isLoading,
    isActive,
  } = liveChat;
  const isEnabled =
    !hasError && isInitialized && isActiveBusinessHours && !isAuthenticated;

  return {
    setLiveChat,
    isEnabled,
    isActiveBusinessHours,
    isInitialized,
    hasError,
    isLoading,
    isActive,
  };
};
