import { SectionContainer, SectionRoot } from '../container-c';
import { desktop, tablet } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

export const ConnectSectionStyles = {
  Section: styled(SectionRoot)<{
    backgroundColor?: string;
  }>`
    background-color: ${(props) => props.backgroundColor};
  `,
  Container: styled(SectionContainer)`
    padding-block: 3rem;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    ${tablet} {
      padding-block: 4rem 7.5rem;
      gap: 2.5rem;
    }
  `,
  Title: styled(MDSText.HeroMd)`
    text-align: center;
  `,
  CTAsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${tablet} {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  BoxContainer: styled.div`
    ${desktop} {
      max-width: 30.25rem;
    }
  `,
  Divider: styled.div`
    display: none;

    ${tablet} {
      display: block;
      width: 1px;
      background-color: ${(p) => p.theme.midwestColors.neutralLightHeavy};
    }
  `,
};
