'use client';

import { SearchAdvisor } from '../advisor-search/SearchAdvisor';
import {
  ConnectionOptionsSection,
  DisclaimerSection,
  MicroSiteConnectProps,
} from '@exploration/shared-sections';
import {
  connectDataCallToAction,
  connectDataText,
} from '@thrivent-web/exploration/data';
import React from 'react';

const defaultSectionData = {
  headline: 'Two ways to schedule your free consultation',
  showNewButtonColor: true,
  text: connectDataText,
  callToAction: {
    ...connectDataCallToAction,
    'connect-section-fa-card-button': {
      buttonComponent: <SearchAdvisor dropdown buttonOnly showChevron />,
      href: 'open-modal',
      text: 'Find a financial advisor',
      buttonId: 'find-financial-advisor-advice-cta',
      ariaId: 'Find a financial advisor',
    },
    'connect-section-fa-virtual-card-button': {
      href: 'https://thrivent.com/advice/virtual-advice-team/advisors',
      text: 'Contact the Virtual Advice Team',
      buttonId: 'contact-virtual-advisor-cta',
      ariaId: 'Contact the Virtual Advice Team',
    },
  },
};

export interface ConnectionMethodSectionProps {
  sectionData?: MicroSiteConnectProps;
  disclaimers?: string[];
}

export const ConnectionMethodSection = ({
  sectionData = defaultSectionData,
  disclaimers,
}: ConnectionMethodSectionProps) => {
  return (
    <>
      <ConnectionOptionsSection sectionData={sectionData} />
      <DisclaimerSection disclaimers={disclaimers} />
    </>
  );
};
