'use client';

import {
  AnchorButton,
  Chevron,
  Container,
  SearchInput,
  StyledContainer,
} from './SearchAdvisorDropdown.styles';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

type Props = {
  interactiveSearch?: boolean;
  minimal?: boolean;
  ctaText?: string;
  buttonOnly?: boolean;
  id?: string;
  showChevron?: boolean;
};

export const SearchAdvisorClassic = ({
  interactiveSearch,
  minimal,
  ctaText,
  buttonOnly = false,
  id,
  showChevron = false,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const router = useRouter();

  const goToAdvisorPage = (url = '') => {
    let value = '';
    if (inputValue) {
      value = `?qp=${inputValue}`;
    }
    router.push(`${url}${value}`);
  };

  const renderAnchorButton = () => (
    <AnchorButton
      role="link"
      href="https://local.thrivent.com/"
      data-analytics-id={id}
      interactiveSearch={interactiveSearch}
      buttonOnly={buttonOnly}
      showChevron={showChevron}
      minimal={minimal}
      onClick={() => goToAdvisorPage('https://local.thrivent.com/')}
    >
      {ctaText || 'Find financial advisors'} {showChevron && <Chevron />}
    </AnchorButton>
  );

  return buttonOnly ? (
    renderAnchorButton()
  ) : (
    <Container interactiveSearch={interactiveSearch} minimal={minimal}>
      <StyledContainer interactiveSearch={interactiveSearch} minimal={minimal}>
        <SearchInput
          name="search-input"
          label="Search by location"
          placeholder="Zip or City, State"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.currentTarget.value)
          }
        />
        {renderAnchorButton()}
      </StyledContainer>
    </Container>
  );
};
