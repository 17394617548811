import {
  CheckboxChecked,
  CheckboxIndeterminate,
  CheckboxUnchecked,
  CheckboxUncheckedBasicHover,
} from '@midwest/web/base';
import styled from 'styled-components';

export const CheckboxCheckedIcon = styled((props) => (
  <CheckboxChecked id={`action-bar-svg-checked-${props.name}`} {...props} />
))`
  path {
    fill: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.borderDisabled
        : p.$basicHover
          ? p.theme.midwestColors.identityBrandPrimaryHeavy
          : p.basic
            ? p.theme.midwestColors.identityBrandPrimaryStrong
            : p.theme.midwestColors.identityBrandPrimaryMedium};
  }
`;

export const CheckboxUncheckedIcon = styled((props) => (
  <CheckboxUnchecked id={`action-bar-svg-unchecked-${props.name}`} {...props} />
))`
  path {
    fill: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.borderDisabled
        : p.isHovered
          ? p.theme.midwestColors.componentFormColorBorderHover
          : p.$invalid
            ? p.theme.midwestColors.componentFormColorTextInvalid
            : p.basic
              ? p.theme.midwestColors.textPrimary
              : p.theme.midwestColors.neutralDarkMedium};
  }
`;

export const CheckboxUncheckedBasicHoverIcon = styled((props) => (
  <CheckboxUncheckedBasicHover
    id={`action-bar-svg-unchecked-basic-hover-${props.name}`}
    {...props}
  />
))`
  path {
    fill: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.borderDisabled
        : p.basic
          ? p.theme.midwestColors.textPrimary
          : p.theme.midwestColors.componentFormColorBorderHover};
  }
`;

export const CheckboxIndeterminateIcon = styled((props) => (
  <CheckboxIndeterminate
    id={`action-bar-svg-indeterminate-${props.name}`}
    {...props}
  />
))`
  path {
    fill: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.neutralLightHeavy
        : p.$basicHover
          ? p.theme.midwestColors.identityBrandPrimaryHeavy
          : p.basic
            ? p.theme.midwestColors.identityBrandPrimaryStrong
            : p.theme.midwestColors.identityBrandPrimaryMedium};
  }
`;
