import * as Dialog from '@radix-ui/react-dialog';
import { desktop, radius } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const DialogRoot = styled(Dialog.Root)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(p) => p.theme.midwestColors.backgroundPrimary};
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(54, 54, 54, 0.5);
  position: fixed;
  inset: 0;

  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: overlayShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: overlayHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes overlayHide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  width: 100vw;
  height: 100vh;
  overflow: auto;

  border-radius: ${radius.containerRadius};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: flex-start;
  background-color: #f7f9ff;
  padding: 1.5rem;

  z-index: 599;
  ${desktop} {
    padding: 3rem;
  }

  @media screen and (max-width: 1130px) {
    padding: 1.5rem;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: contentShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: contentHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translateX(-50%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translateX(0%) scale(1);
    }
  }
  @keyframes contentHide {
    from {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
    to {
      opacity: 0;
      transform: translateX(-50%) scale(0.96);
    }
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  flex-grow: 0;
`;
export const DialogTitle = styled(Dialog.Title)`
  font-size: 2rem;
`;
export const DialogClose = styled(Dialog.Close)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9px;
  border-radius: 6px;
  border: 1px solid
    var(--component-button-color-border-subtle-primary-default, #7095ff);
  background: var(--component-button-color-background-subtle-default, #fff);
  width: 3.5rem;
  height: 3.5rem;
  &:focus,
  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.midwestColors.backgroundInfoSubtle};
  }
`;

export const DialogInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  width: 100%;

  ${desktop} {
    gap: 5rem;
    flex-direction: row;
    justify-content: start;
  }

  @media screen and (min-width: 1024px) and (max-width: 1130px) {
    gap: 1rem;
  }
`;
