import { LiveChatAtom, LiveChatWindow } from '../types';

// --------------------------------------------------------------------------

/**
 * launchLiveChat
 *
 * Allows chat window to pop up when called
 * @param function - setLiveChat jotai setter
 * @returns Promise<boolean>
 */
export const launchLiveChat = (
  setLiveChat: (callback: (prev: LiveChatAtom) => LiveChatAtom) => void,
) => {
  try {
    const liveChatWindow = global.window as LiveChatWindow;
    setLiveChat((prev) => ({
      ...prev,
      isLoading: true,
    }));
    return liveChatWindow.embeddedservice_bootstrap?.utilAPI
      .launchChat()
      .then((e: string) => {
        setLiveChat((prev) => ({
          ...prev,
          isLoading: false,
          isActive: true,
        }));
        return true;
      })
      .catch((error: string) => {
        // eslint-disable-next-line no-console
        console.error('Caught Error on Launch', error);
        setLiveChat((prev) => ({
          ...prev,
          isLoading: false,
          hasError: true,
        }));
        return false;
      });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Launching Embedded Messaging: ', err);
    setLiveChat((prev) => ({
      ...prev,
      isLoading: false,
      hasError: true,
    }));
    return Promise.resolve(false);
  }
};
