'use client';

import {
  CustomContainer,
  CustomDropdownIndicator,
  CustomNoOptionsMessage,
  CustomOption,
  CustomSelect,
  CustomSingleValue,
  CustomValueContainer,
  SuffixCustomIndicatorSeparator,
} from '../../ReactSelect/CustomComponents';
import { Error, Wrapper } from '../../ReactSelect/StyledComponents';
import { getSelectStyles } from './Select.styles';
import { SelectOption, SelectProps } from './types';
import { handleEnterEscTab } from '@midwest/web/base';
import {
  FormComponentLayout,
  HelperText,
  LabelAndLink,
} from '@midwest/web/shared';
import { forwardRef, useState } from 'react';
import { MultiValue, SingleValue, createFilter } from 'react-select';
import { useTheme } from 'styled-components';

/**
 ```jsx
 import { MDSSelect } from '@midwest/web/forms';
 ```
 * Select lets users choose one option from list of options. Consider using a select when you have 4 or more options.
 */
export const MDSSelect = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      label,
      name,
      disabled = false,
      linkLabel,
      linkHref,
      helperText,
      placeholder,
      required = false,
      readOnly = false,
      errorText,
      id,
      invalid,
      prefix,
      suffix,
      options,
      defaultValue,
      onChange,
      onBlur,
      value,
      zIndex = 1,
      validationText,
      menuPortalTarget,
      menuPosition,
      captureMenuScroll,
      ...rest
    },
    ref,
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [focused, setFocused] = useState(false);

    const theme = useTheme();

    const handleOpenChange = (isOpen: boolean) => {
      setIsMenuOpen(isOpen);
      setFocused(!focused);
    };

    const CustomSelectComponents = {
      DropdownIndicator: CustomDropdownIndicator,
      IndicatorSeparator: SuffixCustomIndicatorSeparator,
      Option: CustomOption,
      SelectContainer: CustomContainer,
      NoOptionsMessage: CustomNoOptionsMessage,
      ValueContainer: CustomValueContainer,
      SingleValue: CustomSingleValue,
    };

    const matchingDefaultValue = options.find(
      (option) => option.value === defaultValue,
    );

    const onChangeSelect = (
      newValue: SingleValue<SelectOption> | MultiValue<SelectOption>,
    ) => {
      if (newValue) {
        const v = newValue as SelectOption;
        onChange(v.value);
      }
    };

    return (
      <FormComponentLayout
        {...rest}
        onKeyDownCapture={(e) =>
          handleEnterEscTab(e, setIsMenuOpen, isMenuOpen)
        }
      >
        {!!label && (
          <LabelAndLink
            inputId={id}
            label={label}
            linkLabel={linkLabel}
            linkHref={linkHref}
            disabled={disabled}
            focused={isMenuOpen}
            readOnly={readOnly}
            validationText={validationText}
          />
        )}

        {!!helperText && <HelperText>{helperText}</HelperText>}

        <Wrapper
          onMouseDown={() => setIsClicked(true)}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setIsClicked(false);
          }}
          data-testid="wrapper"
          $disabled={disabled}
          $isClicked={isClicked}
        >
          <CustomSelect
            {...rest}
            ref={ref}
            name={name}
            inputId={id}
            aria-label={readOnly ? `read only ${label}` : label}
            aria-required={required}
            aria-readonly={readOnly}
            placeholder={placeholder ? placeholder : ''}
            aria-invalid={invalid}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            menuIsOpen={readOnly ? false : isMenuOpen}
            defaultValue={matchingDefaultValue}
            onMenuOpen={() => handleOpenChange(true)}
            onMenuClose={() => handleOpenChange(false)}
            isSearchable={true}
            isDisabled={disabled}
            isClicked={isClicked}
            prefix={prefix}
            suffix={suffix}
            options={options}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            styles={getSelectStyles(theme)}
            components={CustomSelectComponents}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
            onChange={onChangeSelect}
            onBlur={onBlur}
            value={
              value === ''
                ? null
                : options.find((option) => option.value === value)
            }
            captureMenuScroll={captureMenuScroll}
            menuShouldScrollIntoView={false}
            maxMenuHeight={400}
            readOnly={readOnly}
            filterOption={createFilter({ matchFrom: 'start' })}
            ariaLiveMessages={
              readOnly
                ? {
                    guidance: () => {
                      return 'This field is read only';
                    },
                  }
                : undefined
            }
            zIndex={zIndex}
          />
        </Wrapper>

        {!!errorText && <Error>{errorText}</Error>}
      </FormComponentLayout>
    );
  },
);

MDSSelect.displayName = 'MDSSelect';
