import { filterMappings, radiusMappings } from '../util/filterMappings';
import { lifeStageAtom, radiusAtom } from './searchState';
import { MultiselectOption } from '@midwest/web/forms';
import { Tracking } from '@thrivent-web/analytics';
import { pushToAdobeDataLayer } from '@thrivent-web/analytics-adobe';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import {
  ConjunctionStaticFilter,
  DisjunctionStaticFilter,
  FieldValueStaticFilter,
  FilterCombinator,
  Result,
  SelectableStaticFilter,
  useSearchActions,
  useSearchState,
} from '@yext/search-headless-react';
import { OnSelectParams } from '@yext/search-ui-react';
import { useAtom, useAtomValue } from 'jotai';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

export const useSearch = () => {
  const RESULTS_PER_PAGE = 5;
  const [lifeStages, setLifeStages] = useAtom(lifeStageAtom);
  const [radius, setRadius] = useAtom(radiusAtom);
  const flags = useAtomValue(explorationFeatureFlagAtom);
  const [page, setPage] = useState<number>(0);
  const searchActions = useSearchActions();
  const searchStateStatic = useSearchState((s) => s.filters.static);
  const staticFilters = useMemo(
    () => searchStateStatic ?? [],
    [searchStateStatic],
  );
  const verticalResults = useSearchState((state) => state.vertical.results);
  const totalResultsCount =
    useSearchState((state) => state.vertical.resultsCount) || 0;

  const locationFilter = useMemo(
    () =>
      staticFilters?.filter(
        (filter) =>
          'fieldId' in filter.filter &&
          filter.filter.fieldId === 'builtin.location' &&
          filter?.selected === true,
      ),
    [staticFilters],
  );

  const sendSearchTracking = useCallback(
    (verticalResults?: Result[]) => {
      const advisorSpecialty = lifeStages?.map((specialty) => specialty?.value);
      const location = locationFilter?.[0]?.displayName;
      pushToAdobeDataLayer({
        eventType: Tracking.searchSubmitSuccess,
        eventInfo: {
          searchResultCount: verticalResults?.length || 0,
          searchFilters: {
            advisorSpecialty,
            location,
          },
        },
      });
    },
    [locationFilter, lifeStages],
  );

  const handleSpecialtyFilter = useCallback(
    (options: MultiselectOption[]) => {
      const specialtyFilters = flags['enable-specialty-filter']
        ? options
            .map((option) => {
              const mapping = filterMappings.get(option.value);
              return mapping ? mapping.filter : undefined;
            })
            .filter(
              (
                filter,
              ): filter is FieldValueStaticFilter | DisjunctionStaticFilter =>
                filter !== undefined,
            )
        : [];

      const combinedSpecialtyFilter: DisjunctionStaticFilter = {
        kind: 'disjunction',
        combinator: FilterCombinator.OR,
        filters: specialtyFilters,
      };

      const combinedFilters: ConjunctionStaticFilter = {
        kind: 'conjunction',
        combinator: FilterCombinator.AND,
        filters: locationFilter?.length
          ? [combinedSpecialtyFilter, ...locationFilter.map((lf) => lf.filter)]
          : [combinedSpecialtyFilter],
      };
      const selectableCombinedFilters: SelectableStaticFilter = {
        filter: combinedFilters,
        selected: true,
        displayName: 'Combined Filters',
      };

      setLifeStages(options);
      searchActions.setStaticFilters([
        selectableCombinedFilters,
        ...locationFilter,
      ]);
    },
    [setLifeStages, searchActions, flags, locationFilter],
  );

  const handleLocationFilter = useCallback(
    ({
      currentFilter,
      executeFilterSearch,
      newDisplayName,
      newFilter,
      setCurrentFilter,
    }: OnSelectParams) => {
      if (currentFilter) {
        searchActions.setFilterOption({
          filter: currentFilter,
          selected: false,
        });
      }
      searchActions.setFilterOption({
        filter: newFilter,
        displayName: newDisplayName,
        selected: true,
      });
      setCurrentFilter(newFilter);
      executeFilterSearch(newDisplayName);
    },
    [searchActions],
  );

  const handleSearch = useCallback(
    async (newPage?: number | MouseEvent<HTMLButtonElement>) => {
      const pageToUse = typeof newPage === 'number' ? newPage : page;
      handleSpecialtyFilter(lifeStages);
      searchActions.setVerticalLimit(RESULTS_PER_PAGE);
      searchActions.setOffset(pageToUse * RESULTS_PER_PAGE);

      searchActions.setLocationRadius(parseInt(radius.value));
      const result = await searchActions.executeVerticalQuery();
      sendSearchTracking(result?.verticalResults?.results);
    },
    [
      handleSpecialtyFilter,
      sendSearchTracking,
      lifeStages,
      searchActions,
      page,
      radius,
    ],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      handleSearch(newPage);
    },
    [handleSearch],
  );

  const handleRadius = useCallback(
    (newRadius: string) => {
      const mapping = radiusMappings.find(
        (option) => option.value === newRadius,
      );
      if (mapping) {
        setRadius(mapping);
      } else {
        setRadius({ label: '25 mi', value: '40234' });
      }
    },
    [setRadius],
  );

  return {
    page,
    RESULTS_PER_PAGE,
    verticalResults,
    totalResultsCount,
    handleSearch,
    handlePageChange,
    handleRadius,
    staticFilters,
    lifeStages,
    radius,
    handleSpecialtyFilter,
    handleLocationFilter,
    sendSearchTracking,
  };
};
