import {
  CTAConnectionOptions,
  CTAConnectionOptionsProps,
} from './cta-connection-options';
import { svgIcons } from '@exploration/icons';
import {
  ConnectSection as ConnectSectionComponent,
  ConnectSectionProps,
  IconCardProps,
} from '@exploration/shared-components';
import { headlessPageDataAtom } from '@exploration/ui-utils';
import { explorationFeatureFlagAtom } from '@thrivent-web/exploration/feature-management';
import { useAtomValue } from 'jotai';

// --------------------------------------------------------------------------

// These types should be temporary until we have this connected to BS
export interface MicroSiteCallToAction {
  buttonComponent?: React.ReactNode;
  href?: string;
  text?: string;
  buttonId?: string;
  ariaId?: string;
}

export interface MicroSiteConnectProps {
  headline?: string;
  callToAction?: { [key: string]: MicroSiteCallToAction };
  backgroundColor?: string;
  id?: string;
  showNewButtonColor?: boolean;
  text?: Record<string, string>;
}

// --------------------------------------------------------------------------

// Once all the pages are moved to the "new" data format, this will go away
/** @deprecated */
export const ConnectSectionDeprecated = ({
  withChatData,
  withoutChatData,
  backgroundColor,
  id,
}: {
  withoutChatData?: ConnectSectionProps;
  withChatData?: CTAConnectionOptionsProps;
  backgroundColor?: string;
  id?: string;
}) => {
  const flags = useAtomValue(explorationFeatureFlagAtom);
  if (
    (flags['show-live-chat'] || flags['show-virtual-assistant-chat']) &&
    withChatData
  ) {
    return (
      <CTAConnectionOptions
        {...withChatData}
        id={id ? id : withChatData?.id}
        backgroundColor={backgroundColor}
      />
    );
  }

  if (!withoutChatData) {
    return null;
  }

  return (
    <ConnectSectionComponent
      {...withoutChatData}
      id={id ? id : withoutChatData?.id}
      backgroundColor={backgroundColor}
    />
  );
};

// This will eventually be the "ConnectSection to rule them all"
// TODO: Find a way to make this a "Reusable" element in BS
/**
 * text: {
 *  // ------ Without chat ------
 *  'connect-section-title': Title without chat
 *  // L
 *  'connect-section-fa-card-icon':  Left most card icon
 *  'connect-section-fa-card-title':  Left most card title
 *  'connect-section-fa-card-description':  Left most card description
 *  // R
 *  'connect-section-fa-virtual-card-icon':  Right most card icon
 *  'connect-section-fa-virtual-card-title':  Right most card title
 *  'connect-section-fa-virtual-card-description':  Right most card description
 *
 *  // ------ With chat ------
 *  'connect-section-chat-title': Title chat
 *  // L
 *  'connect-section-chat-left-card-icon':  Left most card icon
 *  'connect-section-chat-left-card-title':  Left most card title
 *  'connect-section-chat-left-card-description':  Left most card description
 *  // R
 *  'connect-section-chat-right-card-icon':  Right most card icon
 *  'connect-section-chat-right-card-title':  Right most card title
 *  'connect-section-chat-right-card-description':  Right most card description
 *  // Virtual Assistant
 *  'connect-section-chat-va-main-card-icon':  Middle card icon
 *  'connect-section-chat-va-main-card-title':  Middle card title
 *  'connect-section-chat-va-main-card-description':  Middle card description
 *  // Live Chat (active)
 *  'connect-section-chat-live-main-card-icon':  Middle card icon
 *  'connect-section-chat-live-main-card-title':  Middle card title
 *  'connect-section-chat-live-main-card-description':  Middle card description
 *  // Live Chat (inactive)
 *  'connect-section-chat-live-main-inactive-card-icon':  Middle card icon
 *  'connect-section-chat-live-main-inactive-card-title':  Middle card title
 *  'connect-section-chat-live-main-inactive-card-description':  Middle card description
 *
 * }
 * callToAction: {
 *  // ------ Without chat ------
 *  'connect-section-fa-card-button':  Left most card CTA
 *  'connect-section-fa-virtual-card-button':  Right most card CTA
 *
 *  // ------ With chat ------
 *  'connect-section-chat-left-card-button':  Left most card CTA
 *  'connect-section-chat-right-card-button':  Right most card CTA
 *  // Virtual Assistant
 *  'connect-section-chat-va-main-card-button': Middle card CTA ** Button action replaced by chat **
 *  // Live Chat
 *  'connect-section-chat-live-main-card-button': Middle card CTA ** Button action replaced by chat **
 * }
 */
export const ConnectionOptionsSection = ({
  backgroundColor,
  id,
  sectionData,
}: {
  backgroundColor?: string;
  id?: string;
  sectionData?: MicroSiteConnectProps;
}) => {
  const componentData = useAtomValue(headlessPageDataAtom);
  const flags = useAtomValue(explorationFeatureFlagAtom);
  let { text, callToAction } = componentData;

  if (sectionData && sectionData.text && sectionData.callToAction) {
    text = sectionData.text;
    callToAction = sectionData.callToAction;
  }

  const combinedCallToActionsWithChat: IconCardProps[] = [
    {
      key: 'section-1',
      icon: text?.['connect-section-chat-left-card-icon'] as svgIcons,
      headline: text?.['connect-section-chat-left-card-title'],
      subHeadline: text?.['connect-section-chat-left-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-chat-left-card-button']?.href,
      ...callToAction?.['connect-section-chat-left-card-button'],
    },
    {
      key: 'section-2',
      icon: text?.['connect-section-chat-right-card-icon'] as svgIcons,
      headline: text?.['connect-section-chat-right-card-title'],
      subHeadline: text?.['connect-section-chat-right-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-chat-right-card-button']?.href,
      ...callToAction?.['connect-section-chat-right-card-button'],
    },
    {
      key: 'section-live-chat-virtual-assistant',
      icon: text?.['connect-section-chat-va-main-card-icon'] as svgIcons,
      headline: text?.['connect-section-chat-va-main-card-title'],
      subHeadline: text?.['connect-section-chat-va-main-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-chat-va-main-card-button']?.href,
      ...callToAction?.['connect-section-chat-va-main-card-button'],
    },
    {
      key: 'section-live-chat-enabled',
      icon: text?.['connect-section-chat-live-main-card-icon'] as svgIcons,
      headline: text?.['connect-section-chat-live-main-card-title'],
      subHeadline: text?.['connect-section-chat-live-main-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-chat-live-main-card-button']?.href,
      ...callToAction?.['connect-section-chat-live-main-card-button'],
    },
    {
      key: 'section-live-chat-disabled',
      icon: text?.[
        'connect-section-chat-live-main-inactive-card-icon'
      ] as svgIcons,
      headline: text?.['connect-section-chat-live-main-inactive-card-title'],
      subHeadline:
        text?.['connect-section-chat-live-main-inactive-card-description'],
      buttonType: 'button',
      link: callToAction?.[
        'connect-section-chat-live-main-inactive-card-button'
      ]?.href,
      ...callToAction?.['connect-section-chat-live-main-inactive-card-button'],
    },
  ];
  if (flags['show-live-chat'] || flags['show-virtual-assistant-chat']) {
    return (
      <CTAConnectionOptions
        headline={text['connect-section-chat-title']}
        callToAction={combinedCallToActionsWithChat}
        id={id}
        backgroundColor={backgroundColor}
        showNewButtonColor={sectionData?.showNewButtonColor}
      />
    );
  }

  const combinedCallToActions: IconCardProps[] = [
    {
      icon: text?.['connect-section-fa-card-icon'] as svgIcons,
      headline: text?.['connect-section-fa-card-title'],
      subHeadline: text?.['connect-section-fa-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-fa-card-button']?.href,
      ...callToAction?.['connect-section-fa-card-button'],
    },
    {
      icon: text?.['connect-section-fa-virtual-card-icon'] as svgIcons,
      headline: text?.['connect-section-fa-virtual-card-title'],
      subHeadline: text?.['connect-section-fa-virtual-card-description'],
      buttonType: 'button',
      link: callToAction?.['connect-section-fa-virtual-card-button']?.href,
      ...callToAction?.['connect-section-fa-virtual-card-button'],
    },
  ];

  return (
    <ConnectSectionComponent
      backgroundColor={backgroundColor}
      headline={sectionData?.headline || text['connect-section-title']}
      callToAction={combinedCallToActions}
      id={id}
      showNewButtonColor={sectionData?.showNewButtonColor}
    />
  );
};
