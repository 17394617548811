'use client';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HiddenAriaLive,
  LastItemContainer,
  MorePages,
  NextButton,
  PageButton,
  PageButtonText,
  PageButtonTextContainer,
  PaginationContainer,
  PrevButton,
} from './PaginationStyles';
import { PaginationProps } from './PaginationTypes';
import { ELLIPSIS, generatePagination } from './PaginationUtilities';

/**
 ```tsx
 import { Pagination } from '@midwest/web/pagination';
 ```
 * Help users navigate through a list of items or pages. `totalNumberOfItems` / `itemCount` = number of pagination items
 */
export const MDSPagination = ({
  itemCount,
  paginationSize,
  onPageChange,
  totalNumberOfItems,
  currentPage,
}: PaginationProps) => {
  const total = Math.ceil(totalNumberOfItems / itemCount);
  const pages = generatePagination(currentPage, total, paginationSize);

  const renderPageButton = (item: number, key?: string) => {
    const buttonProps = {
      'aria-label': `Page ${item} of ${total}`,
      'data-testid': `item-${item}`,
      selected: item === currentPage + 1,
      'aria-current': item === currentPage + 1,
      onClick: () => {
        const page = item - 1;
        if (page < total) {
          onPageChange(page);
        }
      },
    };

    return (
      <PageButton key={key} {...buttonProps}>
        <PageButtonTextContainer
          data-testid={`item-text-${item}`}
          selected={item === currentPage + 1}
        >
          <PageButtonText selected={item === currentPage + 1}>
            {item}
          </PageButtonText>
        </PageButtonTextContainer>
      </PageButton>
    );
  };

  return (
    <PaginationContainer>
      <HiddenAriaLive aria-live="polite" aria-atomic="true">
        Now viewing page{currentPage + 1}of{total}.
      </HiddenAriaLive>
      <PrevButton
        aria-label="Previous page of results"
        data-testid="prev-button"
        onClick={() => {
          const prevPage = currentPage - 1;
          if (prevPage >= 0) {
            onPageChange(prevPage);
          }
        }}
        disabled={currentPage === 0 || totalNumberOfItems === 0}
      >
        <ChevronLeftIcon disabled={currentPage === 0} title="Chevron Left" />
      </PrevButton>
      {pages.map((item, index) => {
        const key = item === ELLIPSIS ? `more-pages-${index}` : `page-${item}`;
        if (item === ELLIPSIS) {
          return (
            <MorePages data-testid="more-pages" tabIndex={-1} key={key}>
              <span>...</span>
            </MorePages>
          );
        } else if (item !== pages[pages.length - 1]) {
          return renderPageButton(item, key);
        } else {
          return (
            <LastItemContainer key={key}>
              {renderPageButton(item)}
              <NextButton
                aria-label="Next page of results"
                data-testid="next-button"
                onClick={() => {
                  const nextPage = currentPage + 1;
                  if (nextPage < total) {
                    onPageChange(nextPage);
                  }
                }}
                disabled={currentPage === total - 1 || totalNumberOfItems === 0}
              >
                <ChevronRightIcon
                  disabled={currentPage === total - 1}
                  title="Chevron Right"
                />
              </NextButton>
            </LastItemContainer>
          );
        }
      })}
    </PaginationContainer>
  );
};
