'use client';

import { SearchAdvisor } from '../../advisor-search/SearchAdvisor';
import {
  QuickConnectBody,
  QuickConnectButton,
  QuickConnectLayout,
  QuickConnectSectionContainer,
  QuickConnectSectionRoot,
  QuickConnectTitle,
} from './QuickConnectSection.style';

export const QuickConnectSection = ({
  title,
  bodyCopy,
  dropdownPlaceholderText,
  interactiveSearch,
}: {
  title: string;
  bodyCopy: string;
  dropdownPlaceholderText?: string;
  interactiveSearch?: boolean;
}) => {
  return (
    <QuickConnectSectionRoot>
      <QuickConnectSectionContainer>
        <QuickConnectLayout>
          <QuickConnectTitle as="h2">{title}</QuickConnectTitle>
          <QuickConnectBody as="p">{bodyCopy}</QuickConnectBody>
          {interactiveSearch ? (
            <SearchAdvisor
              id="quick-connect-search-advisors-with-dropdown"
              dropdown
              dropdownPlaceholderText={dropdownPlaceholderText}
              minimal
              ctaText="Search Advisors"
            />
          ) : (
            <QuickConnectButton
              weight="subtle"
              href="/advice/virtual-advice-team/advisors"
              data-testid="find-advisor-button"
              data-analytics-cta-name="find-advisor-button"
            >
              Schedule your virtual meeting
            </QuickConnectButton>
          )}
        </QuickConnectLayout>
      </QuickConnectSectionContainer>
    </QuickConnectSectionRoot>
  );
};
