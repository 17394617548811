import { CustomStyles } from '../../ReactSelect/CustomStyles';
import { SelectOption } from './types';
import { GroupBase, StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

export const getSelectStyles = (
  theme: DefaultTheme,
): StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> => ({
  container: CustomStyles(theme).container,
  control: CustomStyles(theme).control,
  input: (baseStyles) => ({
    ...baseStyles,
    color: theme.midwestColors.textPrimary,
    caretColor: 'transparent', // Hide the blinking cursor for normal Select.
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
  }),
  singleValue: CustomStyles(theme).singleValue,
  placeholder: CustomStyles(theme).placeholder,
  menu: CustomStyles(theme).menu,
  menuList: CustomStyles(theme).menuList,
  menuPortal: CustomStyles(theme).menuPortal,
});
