import { svgIcons } from '@exploration/icons';

// --------------------------------------------------------------------------

/** @deprecated - if you are accessing this, you're using the old CMS format */
export const connectData = {
  headline: 'Two ways to schedule your free consultation',
  callToAction: [
    {
      icon: svgIcons.pinOnMap,
      headline: 'A local financial advisor near you',
      subHeadline:
        'Find a financial advisor in your community and start building a relationship to get you on the right path toward your goals.',
      link: 'https://local.thrivent.com/',
      text: 'Find a financial advisor',
      buttonId: 'find-financial-advisor-advice-cta',
      ariaId: 'Find a financial advisor',
    },
    {
      icon: svgIcons.manOnLaptop,
      headline: 'Virtual Advice Team',
      subHeadline: `Talk to a financial advisor, by video or over the phone, when and how it's convenient for you.`,
      link: '/advice/virtual-advice-team/advisors',
      text: 'Contact the Virtual Advice Team',
      buttonId: 'contact-virtual-advisor-cta',
      ariaId: 'Contact the Virtual Advice Team',
    },
  ],
};

/** @deprecated - if you are accessing this, you're using the old CMS format */
export const connectDataAlternate = {
  headline: 'Take the first step to financial clarity',
  callToAction: [
    {
      ...connectData.callToAction[0],
      headline: 'Find a financial advisor near you',
    },
    {
      ...connectData.callToAction[1],
      headline: 'Virtual Advice Team',
    },
  ],
};

/** @deprecated - if you are accessing this, you're using the old CMS format */
export const ctaConnectionOptionsDataDeprecated = {
  ...connectDataAlternate,
  callToAction: [
    {
      ...connectData.callToAction[0],
      key: 'section-1',
    },
    {
      ...connectData.callToAction[1],
      icon: svgIcons.devices,
      key: 'section-2',
    },
    {
      icon: svgIcons.peopleChatting,
      headline: 'Chat with us',
      subHeadline:
        '<p>Use our chat feature to talk to one of our trained representatives.</p> <br /> <strong>Hours: Monday - Friday, 7:30 a.m. to 5:30 p.m. CT</strong>',
      text: 'Learn More',
      buttonId: 'live-chat-button',
      ariaId: 'Learn More',
      key: 'section-live-chat-enabled',
    },
    {
      icon: svgIcons.peopleChatting,
      headline: 'Chat with us',
      subHeadline:
        'It\'s after hours! <br /> <p><strong>Hours: Monday - Friday, 7:30 a.m. to 5:30 p.m. CT</strong></p> <br />You can still connect via our <a href="/about-us/support">contact form</a>. Messages will be responded to within two business days.',
      buttonId: 'live-chat-after-hours',
      key: 'section-live-chat-disabled',
    },
    {
      icon: svgIcons.manOnLaptop,
      headline: 'Chat with us',
      subHeadline:
        "Chat with our automated assistant to learn more about Thrivent's offerings.",
      text: 'Learn More',
      ariaId: 'Learn More',
      buttonId: 'live-chat-virtual-assistant',
      key: 'section-live-chat-virtual-assistant',
    },
  ],
};

/** @deprecated - if you are accessing this, you're using the old CMS format */
export const ctaConnectionOptionsLegalTextDeprecated = `By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. By selecting "Learn More" you will begin an automated connection with Thrivent's Virtual Assistant. By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.`;

/** @deprecated - if you are accessing this, you're using the old CMS format */
export const connectDataLegalTextDeprecated = `By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.`;

export const connectDataText = {
  // ------ Without chat ------
  'connect-section-title': 'Two ways to schedule your free consultation',
  // L
  'connect-section-fa-card-icon': 'svgPinOnMap',
  'connect-section-fa-card-title': 'A local financial advisor near you',
  'connect-section-fa-card-description':
    'Find a financial advisor in your community and start building a relationship to get you on the right path toward your goals.',
  // R
  'connect-section-fa-virtual-card-icon': 'svgManOnLaptop',
  'connect-section-fa-virtual-card-title': 'Virtual Advice Team',
  'connect-section-fa-virtual-card-description': `Talk to a financial advisor, by video or over the phone, when and how it's convenient for you.`,

  // ------ With chat ------
  'connect-section-chat-title': 'Take the first step to financial clarity',
  // L
  'connect-section-chat-left-card-icon': 'svgPinOnMap',
  'connect-section-chat-left-card-title': 'Find a financial advisor near you',
  'connect-section-chat-left-card-description':
    'Find a financial advisor in your community and start building a relationship to get you on the right path toward your goals.',
  // R
  'connect-section-chat-right-card-icon': 'devices',
  'connect-section-chat-right-card-title': 'Virtual Advice Team',
  'connect-section-chat-right-card-description': `Talk to a financial advisor, by video or over the phone, when and how it's convenient for you.`,
  // Virtual Assistant
  'connect-section-chat-va-main-card-icon': 'svgManOnLaptop',
  'connect-section-chat-va-main-card-title': 'Chat with us',
  'connect-section-chat-va-main-card-description': `Chat with our automated assistant to learn more about Thrivent's offerings.`,
  // Live Chat (active)
  'connect-section-chat-live-main-card-icon': 'peopleChatting',
  'connect-section-chat-live-main-card-title': 'Chat with us',
  'connect-section-chat-live-main-card-description':
    '<p>Use our chat feature to talk to one of our trained representatives.</p> <br /> <strong>Hours: Monday - Friday, 7:30 a.m. to 5:30 p.m. CT</strong>',
  // Live Chat (inactive)
  'connect-section-chat-live-main-inactive-card-icon': 'peopleChatting',
  'connect-section-chat-live-main-inactive-card-title': 'Chat with us',
  'connect-section-chat-live-main-inactive-card-description': `It's after hours! <br /> <p><strong>Hours: Monday - Friday, 7:30 a.m. to 5:30 p.m. CT</strong></p> <br />You can still connect via our <a href="/about-us/support">contact form</a>. Messages will be responded to within two business days.`,

  // ------ Legal ------
  // No Chat
  'connect-section-legal-text': `By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.`,
  // VA Chat
  'connect-section-va-legal-text': `By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. By selecting "Learn More" you will begin an automated connection with Thrivent's Virtual Assistant. By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.`,
  // Live Chat
  'connect-section-live-legal-text': `By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. By selecting "Start a Conversation" you will TODO WHEN LIVE CHAT IS READY. By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.`,
};

export const connectDataCallToAction = {
  // ------ Without chat ------
  // L
  'connect-section-fa-card-button': {
    text: 'Find a financial advisor',
    href: 'https://local.thrivent.com/',
    buttonId: 'find-financial-advisor-advice-cta',
    ariaId: 'Find a financial advisor',
  },
  // R
  'connect-section-fa-virtual-card-button': {
    text: 'Contact the Virtual Advice Team',
    href: '/advice/virtual-advice-team/advisors',
    buttonId: 'contact-virtual-advisor-cta',
    ariaId: 'Contact the Virtual Advice Team',
  },

  // ------ With chat ------
  // L
  'connect-section-chat-left-card-button': {
    text: 'Find a financial advisor',
    href: 'https://local.thrivent.com/',
    buttonId: 'find-financial-advisor-advice-cta',
    ariaId: 'Find a financial advisor',
  },
  // R
  'connect-section-chat-right-card-button': {
    text: 'Contact the Virtual Advice Team',
    href: '/advice/virtual-advice-team/advisors',
    buttonId: 'contact-virtual-advisor-cta',
    ariaId: 'Contact the Virtual Advice Team',
  },
  // Virtual Assistant
  'connect-section-chat-va-main-card-button': {
    text: 'Learn More',
    buttonId: 'live-chat-virtual-assistant',
    ariaId: 'Learn More',
  },
  // Live Chat (active)
  'connect-section-chat-live-main-card-button': {
    text: 'Learn More',
    buttonId: 'live-chat-virtual-assistant',
    ariaId: 'Learn More',
  },
};
