'use client';

import { ChevronRight as ChevronRightIcon } from '@exploration/shared-components';
import { MDSAnchorButton, MDSButton } from '@midwest/web/button';
import { MDSMultiselect, MDSTextInput } from '@midwest/web/forms';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled, { StyledProps } from 'styled-components';

interface DropdownProps {
  buttonOnly?: boolean;
  interactiveSearch?: boolean;
  minimal?: boolean;
  showChevron?: boolean;
}

export const Container = styled.div<StyledProps<DropdownProps>>`
  width: 100%;
  display: ${({ interactiveSearch, minimal }) =>
    interactiveSearch || minimal ? 'flex' : 'block'};
  justify-content: center;
  padding: ${({ minimal }) => (minimal ? '0' : '1rem')};
  margin-top: ${({ minimal }) => (minimal ? '0' : 'unset')};
`;

export const StyledContainer = styled.div<DropdownProps>`
  max-width: 38.5rem;
  width: 100%;
  padding: ${({ interactiveSearch }) =>
    interactiveSearch ? '1.5rem 2rem' : '0'};
  border-radius: ${({ interactiveSearch }) =>
    interactiveSearch ? '0.5rem' : 'none'};
  box-shadow: ${({ interactiveSearch }) =>
    interactiveSearch ? '4px 4px 24px 0px rgba(0, 0, 0, 0.1)' : 'none'};
  display: flex;
  flex-direction: column;

  gap: ${({ interactiveSearch, minimal }) =>
    interactiveSearch || minimal ? '1rem' : '0.5rem'};
  ${({ interactiveSearch, minimal }) =>
    (interactiveSearch || minimal) &&
    `${tablet} {
      flex-direction: row;
    }`}
`;

export const Dropdown = styled(MDSMultiselect)<DropdownProps>`
  flex: 1;
  height: 3rem;
  z-index: 1;
  min-width: 16rem;
  ${({ minimal }) =>
    minimal &&
    `
    & label {
      opacity: 0;
    }
  `}
`;

export const Button = styled(MDSButton)<DropdownProps>`
  display: block;
  height: 3rem;
  margin-top: ${({ buttonOnly }) => (buttonOnly ? '0.5rem' : '0')};
  min-width: 100%;

  ${tablet} {
    min-width: 14rem;
  }

  ${({ showChevron }) =>
    showChevron &&
    `
    display: unset;
    height: unset;
    min-width: unset;
    font-weight: 700;
    margin-top: 0.25rem;
    text-decoration: none;
    width: 100%;
    ${tablet} {
      width: fit-content;
    }
  `}

  ${({ interactiveSearch, buttonOnly }) =>
    !interactiveSearch &&
    !buttonOnly &&
    `${desktop} {
      margin-top: 1.5rem;
    }`}

  ${({ interactiveSearch }) =>
    interactiveSearch &&
    `${tablet} {
      width: auto;
      margin-top: 1.5rem;
    }`}

  ${({ theme, minimal }) =>
    minimal &&
    `
      background-color: ${theme.midwestColors.componentButtonColorBackgroundStrongSecondaryDefault};
      color: ${theme.midwestColors.componentButtonColorTextSubtlePrimary};
      outline: none;
      min-width: 11rem !important;
      flex-basis: auto;
      width: auto;
      margin-top: 0;
      ${tablet} {
        margin-top: 24px;
        flex-basis: 11rem;
      }
    `}
`;

export const Chevron = styled(ChevronRightIcon)`
  margin-left: -0.25rem;
`;

// Classic

export const AnchorButton = styled(MDSAnchorButton)<DropdownProps>`
  display: block;
  height: 3rem;
  margin-top: ${({ buttonOnly }) => (buttonOnly ? '0.5rem' : '0')};
  min-width: 100%;

  ${tablet} {
    min-width: 14rem;
  }

  ${({ showChevron }) =>
    showChevron &&
    `
    display: unset;
    height: unset;
    min-width: unset;
    font-weight: 700;
    margin-top: 0.25rem;
    text-decoration: none;
    width: 100%;
    ${tablet} {
      width: fit-content;
    }
  `}

  ${({ interactiveSearch, buttonOnly }) =>
    !interactiveSearch &&
    !buttonOnly &&
    `${desktop} {
      margin-top: 1.5rem;
    }`}

  ${({ interactiveSearch }) =>
    interactiveSearch &&
    `${tablet} {
      width: auto;
      margin-top: 1.5rem;
    }`}

  ${({ theme, minimal }) =>
    minimal &&
    `
      background-color: ${theme.midwestColors.componentButtonColorBackgroundStrongSecondaryDefault};
      color: ${theme.midwestColors.componentButtonColorTextSubtlePrimary};
      outline: none;
      min-width: 11rem !important;
      flex-basis: auto;
      width: auto;
      margin-top: 0;
      ${tablet} {
        margin-top: 24px;
        flex-basis: 11rem;
      }
    `}
`;

export const SearchInput = styled(MDSTextInput)`
  width: 100%;
`;
