'use client';

import {
  BaseBadgeStyle,
  CustomIcon,
  InfoBadgeStyle,
  LargeLabel,
  LeftIconLayout,
  NegativeBadgeStyle,
  NeutralBadgeStyle,
  PositiveBadgeStyle,
  SmallLabel,
  WarningBadgeStyle,
} from './BadgeStyles';
import { ReactElement, forwardRef } from 'react';
import styled from 'styled-components';

//This File is deprecated! Please use MDSBadge instead.

type BadgeSizes = 'small' | 'large';
type BadgeType = 'positive' | 'negative' | 'warning' | 'neutral' | 'info';

type BadgeProps = {
  label: string;
  size: BadgeSizes;
  type: BadgeType;
  icon?: ReactElement;
  iconAltText?: string;
};

const BaseBadge = styled.div`
  ${BaseBadgeStyle}
`;

const PositiveBadge = styled(BaseBadge)`
  ${PositiveBadgeStyle}
`;

const WarningBadge = styled(BaseBadge)`
  ${WarningBadgeStyle}
`;

const NegativeBadge = styled(BaseBadge)`
  ${NegativeBadgeStyle}
`;

const NeutralBadge = styled(BaseBadge)`
  ${NeutralBadgeStyle}
`;

const InfoBadge = styled(BaseBadge)`
  ${InfoBadgeStyle}
`;

const badgeTypes = {
  positive: PositiveBadge,
  warning: WarningBadge,
  negative: NegativeBadge,
  neutral: NeutralBadge,
  info: InfoBadge,
};

/**
 * @deprecated Please use MDSBadge instead.
 *
 * Badges are used to inform users of the current status of an item or data. Often used to show users a change in that item's status.
 * The children of this component are rendered as the content of the badge.
 */
export const Badge = forwardRef(function Badge(
  { label, size, type, icon, iconAltText, ...rest }: BadgeProps,
  ref?: React.Ref<HTMLDivElement>,
) {
  const BadgeType = badgeTypes[type];
  const LabelFont = size === 'large' ? LargeLabel : SmallLabel;
  const alt = iconAltText
    ? { role: 'img', alt: iconAltText }
    : { role: 'presentation' };

  return (
    <BadgeType ref={ref} {...rest}>
      {!!icon && (
        <LeftIconLayout size={size} {...alt}>
          <CustomIcon size={size} as={icon.type} alt={iconAltText} />
        </LeftIconLayout>
      )}
      <LabelFont size={size}>{label}</LabelFont>
    </BadgeType>
  );
});
