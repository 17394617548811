import { AlertFilled, MDSText } from '@midwest/web/base';
import styled from 'styled-components';

const ErrorIconAndTextContainer = styled.div`
  display: flex;
  flex-direction: start;
  align-items: center;
  gap: 6px;
`;

const AlertIcon = styled((props) => <AlertFilled {...props} />)`
  fill: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
  width: 16px;
  height: 16px;
  min-width: 16px;
`;

export const ErrorText = styled(MDSText.BodyMediumSm)`
  color: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
  white-space: pre-wrap;
`;

export const ErrorIconAndText = (props: {
  errorText: string;
  errorId: string;
}) => {
  const { errorText, errorId } = props;

  return (
    <ErrorIconAndTextContainer>
      <AlertIcon role="presentation" />
      <ErrorText id={errorId} role="status">
        {errorText}
      </ErrorText>
    </ErrorIconAndTextContainer>
  );
};
