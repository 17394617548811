import {
  ArrowUnfoldMore,
  MDSText,
  ValidationProps,
  WarningIcon,
} from '@midwest/web/base';
import styled from 'styled-components';

export const Alert = styled((props) => <WarningIcon {...props} />)`
  height: 18px;
  width: 18px;
  margin-right: 12px;
  fill: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
`;

export const Wrapper = styled.div<ValidationProps>`
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  outline: none;
  user-select: text;
`;

export const Error = styled(MDSText.BodyMediumSm)`
  color: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
`;

export const ArrowUnfoldMoreIcon = styled((props) => (
  <ArrowUnfoldMore {...props} />
))<ValidationProps>`
  fill: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.neutralDarkMedium
      : p.theme.midwestColors.neutralDarkHeavy};
  width: 18px;
  height: 18px;
`;

export const Placeholder = styled(MDSText.BodyRegSm)<ValidationProps>`
  height: 20px;
  white-space: nowrap;
  color: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.componentFormColorTextPlaceholder};
`;

export const SelectItem = styled.div<{ $focused?: boolean }>`
  padding: 12px;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 4px;
  fill: ${(p) => p.theme.midwestColors.neutralDarkStrong};
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;

  &:focus,
  &:hover {
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
    fill: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  }

  &[data-state='checked'] {
    background: ${(p) => p.theme.midwestColors.neutralLightMedium};
    font-weight: 600;

    &:focus,
    &:hover {
      background: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
      color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
      fill: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
    }
  }

  background: ${(p) =>
    p.$focused
      ? `${p.theme.midwestColors.identityBrandPrimaryMedium}`
      : `${p.theme.midwestColors.neutralLightWeak}`};

  color: ${(p) =>
    p.$focused
      ? `${p.theme.midwestColors.textPrimaryDarkBG}`
      : `${p.theme.midwestColors.textPrimary}`};
`;

export const CustomContainerStyles = styled.div<{
  $isClicked: boolean;
  $menuIsOpen: boolean;
}>`
  &:focus-within {
    border-radius: 5px;
    box-shadow: ${(p) =>
      !p.$isClicked &&
      `0 0 0 ${p.theme.border.widthFocused} ${p.theme.midwestColors.borderFocus}`};

    ${(p) =>
      p.$menuIsOpen &&
      `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  }
`;

export const NoOptions = styled.div<ValidationProps>`
  padding: 12px;
  background: ${(p) => p.theme.midwestColors.neutralLightWeak};
  cursor: auto;
`;

export const BadgeContainer = styled.div`
  margin-left: 8px;
`;

export const SuffixPrefix = styled(MDSText.BodyRegSm)<ValidationProps>`
  display: flex;
  padding: 0px ${(p) => p.theme.spacing.xxxsm};
  align-items: flex-start;

  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.midwestColors.borderDefault};
  background: ${(p) => p.theme.midwestColors.backgroundSecondary};
  color: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.textPrimary};
`;

export const ReadOnlyInputDisplay = styled.input<ValidationProps>`
  width: 100%;
  padding: 0;
  color: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.textPrimary};
  background: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.backgroundDisabled
      : p.theme.midwestColors.textSecondaryDarkBG};
  font-size: 14px;
  font-weight: 400;
  overflow-y: auto;
  align-self: center;
  outline: none;
  font-style: normal;
  font-family: Inter, sans-serif;
`;

export const StyledCustomValueContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  overflow: hidden;
  height: 22px;
`;

export const LoadingMessageContainer = styled.div`
  padding: 12px;
  background: ${(p) => p.theme.midwestColors.neutralLightWeak};
`;

export const StyledMultiValueRemoveContainer = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 100%;
  padding: 6px;
  fill: ${(p) => p.theme.midwestColors.neutralDarkStrong};

  &:hover {
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
    fill: ${(p) => p.theme.midwestColors.neutralLightWeak};
  }
`;
