'use client';

import { lifeStageFilters } from '../util/filterMappings';
import { useSearch } from './useSearch';
import { MDSText } from '@midwest/web/base';
import { MDSButton } from '@midwest/web/button';
import { MDSMultiselect, MDSSelect } from '@midwest/web/forms';
import { FilterSearch } from '@yext/search-ui-react';
import { useState } from 'react';
import styled from 'styled-components';

const SearchBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;

  border-radius: 16px;
  border: 1px solid
    ${(p) => p.theme.midwestColors.semanticColorSentimentInfoWeak};
  box-shadow: -8px 15px 34.4px 0 rgba(209, 222, 255, 0.5);

  background: ${(p) => p.theme.midwestColors.white};
  height: 108px;
  width: 768px;
`;

const Selections = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & > button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    max-width: 33%;
    overflow: hidden;
    padding: 0 2rem;

    border: none;
    background: transparent;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-of-type {
      border-right: 1px solid #d9d9d9;
    }
  }
`;

const SelectionsOverlay = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  z-index: 2;

  button {
    height: 100%;
    padding: 16px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 16px;
    border: 1px solid #19398c;
    background: #19398c;
    color: ${(p) => p.theme.midwestColors.white};

    text-align: center;

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
  }
`;

const Selector = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
`;

const Control = styled.div`
  flex: 1;
`;

const StyledFilterSearch = styled.div`
  position: relative;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  input {
    width: 100%;
    border: solid #c7c7ca 2px;
    border-radius: 4px;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;

    &:hover,
    &:focus {
      border-color: #141414;
    }
  }

  .sr-only,
  .hidden {
    display: none;
  }

  .absolute {
    position: absolute;
    width: 100%;
    background: ${(p) => p.theme.midwestColors.white};
    z-index: 100;

    & > div {
      border: solid #c7c7ca 2px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0.25rem;

      div[id] {
        padding: 0.25rem;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const SearchButton = styled.div`
  flex: 1;
  padding: 16px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 16px;
  border: 1px solid #19398c;
  background: #19398c;
  color: ${(p) => p.theme.midwestColors.white};

  text-align: center;

  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const meetingOptions = [
  { value: 'virtual', label: 'Online appointment' },
  { value: 'in-person', label: 'In person' },
];

type Props = {
  onResult: () => void;
};

export const SearchAdvisorBox = ({ onResult }: Props) => {
  const [editing, setEditing] = useState<string | null>(null);
  const {
    lifeStages,
    staticFilters,
    handleSpecialtyFilter,
    handleLocationFilter,
    handleSearch,
  } = useSearch();

  const [meetingPreference, setMeetingPreference] = useState(
    meetingOptions[0].value,
  );

  const searchFields = [
    {
      fieldApiName: 'builtin.location',
      entityType: 'location',
    },
  ];

  const selectedStaticFilters = staticFilters.filter(
    (staticFilter) => staticFilter.selected,
  );

  const selectedLocationDisplayName = selectedStaticFilters.find(
    (staticFilter) =>
      'fieldId' in staticFilter.filter &&
      staticFilter.filter.fieldId === 'builtin.location',
  )?.displayName;

  const selectedTopicDisplayNames = selectedStaticFilters
    .filter(
      (staticFilter) =>
        'fieldId' in staticFilter.filter &&
        staticFilter.filter.fieldId === 'c_designations',
    )
    .map((staticFilter) => staticFilter.displayName);

  const optionsSlideOutOpen = editing !== null;

  return (
    <SearchBox>
      {editing === null ? (
        <Selections>
          <button onClick={() => setEditing('location')}>
            <MDSText.BodyHeavySm>Your location</MDSText.BodyHeavySm>
            <div>{selectedLocationDisplayName ?? 'Near you'}</div>{' '}
          </button>
          <button onClick={() => setEditing('type')}>
            <MDSText.BodyHeavySm>Appointment Type</MDSText.BodyHeavySm>
            <div>
              {
                meetingOptions.find(
                  (option) => option.value === meetingPreference,
                )?.label
              }
            </div>
          </button>
          <button onClick={() => setEditing('services')}>
            <MDSText.BodyHeavySm>Topic</MDSText.BodyHeavySm>
            <div>
              {selectedTopicDisplayNames.length > 0
                ? selectedTopicDisplayNames.join(', ')
                : 'All topics'}
            </div>
          </button>

          <ButtonContainer>
            <SearchButton
              onClick={() => {
                handleSearch();
                onResult();
              }}
            >
              Search Advisors
            </SearchButton>
          </ButtonContainer>
        </Selections>
      ) : (
        <SelectionsOverlay
          className={`animate__animated animate__faster ${
            optionsSlideOutOpen ? 'animate__fadeIn' : 'animate__fadeOut'
          }`}
        >
          {editing === 'location' && (
            <Selector>
              <Control>
                <MDSText.BodyHeavySm>Your location</MDSText.BodyHeavySm>
                <StyledFilterSearch>
                  <FilterSearch
                    placeholder="Minneapolis"
                    label=""
                    key="directory-search"
                    searchFields={searchFields}
                    onSelect={handleLocationFilter}
                  />
                </StyledFilterSearch>
              </Control>
              <MDSButton onClick={() => setEditing(null)}>Done</MDSButton>
            </Selector>
          )}

          {editing === 'services' && (
            <Selector>
              <Control>
                <MDSMultiselect
                  placeholder="Select from our offerings"
                  options={lifeStageFilters}
                  name="topic"
                  label="Topic"
                  value={lifeStages}
                  onChange={handleSpecialtyFilter}
                />
              </Control>
              <MDSButton onClick={() => setEditing(null)}>Done</MDSButton>
            </Selector>
          )}

          {editing === 'type' && (
            <Selector>
              <Control>
                <MDSSelect
                  name="meeting-preference"
                  label="Appointment Type"
                  options={meetingOptions}
                  value={meetingPreference}
                  onChange={function (v: string): void {
                    setMeetingPreference(v);
                    setEditing(null);
                  }}
                />
              </Control>
              <MDSButton onClick={() => setEditing(null)}>Done</MDSButton>
            </Selector>
          )}
        </SelectionsOverlay>
      )}
    </SearchBox>
  );
};
