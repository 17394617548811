/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomStyles } from '../../ReactSelect/CustomStyles';
import { MultiselectOption } from './types';
import { Add, CloseIcon, MDSText, ValidationProps } from '@midwest/web/base';
import { textBodyHeavySm } from '@thrivent/midwest-shared';
import { GroupBase, StylesConfig } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

/* -------------------------------------------------------------------------------------------------
 * Multiselect Specific Styled Components
 * -----------------------------------------------------------------------------------------------*/

export const MultiselectOptionLabelText = styled(MDSText.BodyRegSm)`
  cursor: pointer;
`;

export const MultiselectOptionContainer = styled.div`
  display: flex;
  padding: 0px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const MultiselectOptionLabel = styled(MDSText.BodyRegSm)`
  color: ${(p) => p.theme.midwestColors.textSecondary};
  &:hover,
  &:focus {
    color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  }
`;

export const MultiselectMultiValueLabel = styled(MDSText.BodyHeavySm)`
  white-space: nowrap;
  padding: 6px;
`;

export const MultiselectMultiValueAdditional = styled.div<{ count: number }>`
  white-space: nowrap;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &::after {
    content: '+ ${(props) => props.count}';
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border-radius: 5px;
    background: ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
    width: auto;
    padding: 6px;
    font-family: ${textBodyHeavySm.font};
    font-weight: ${textBodyHeavySm.weight};
    font-size: ${textBodyHeavySm.rem}rem;
    line-height: 1.7;
  }
`;

export const MultiselectMultiValueRemove = styled((props) => (
  <CloseIcon {...props} />
))`
  height: 12px;
  width: 12px;
  cursor: pointer;
  fill: inherit;
`;

export const AddIcon = styled((props) => <Add {...props} />)`
  height: 24px;
  width: 24px;
  fill: ${(p) => p.theme.midwestColors.neutralDarkStrong};
`;

export const AddNewOption = styled.div<ValidationProps>`
  padding: 12px;
  background: ${(p) =>
    p.$focused
      ? `${p.theme.midwestColors.backgroundHover}`
      : `${p.theme.midwestColors.neutralLightWeak}`};
  cursor: pointer;
`;

export const IconCreateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

export const MultiselectDropdownOption = styled.div<ValidationProps>`
  padding: 11px;
  background: ${(p) =>
    p.$focused
      ? `${p.theme.midwestColors.backgroundHover}`
      : `${p.theme.midwestColors.neutralLightWeak}`};
  cursor: pointer;
`;

export const MultiselectNoOptions = styled.div<ValidationProps>`
  padding: 12px;
  background: ${(p) =>
    p.$focused
      ? `${p.theme.midwestColors.neutralLightMedium}`
      : `${p.theme.midwestColors.neutralLightWeak}`};
`;

export const MultiselectAddOption = styled.div`
  padding: 12px;
  background: ${(p) => p.theme.midwestColors.neutralLightMedium};
`;

export const CheckboxFocusOutline = styled.span<ValidationProps>`
  border: ${(p) =>
    p.$focused
      ? `2px solid ${p.theme.midwestColors.identityBrandPrimaryHeavy}`
      : 'none'};

  border-radius: 3px;
  padding-top: 8px;
`;
export const getMultiselectStyles = (
  theme: DefaultTheme,
): StylesConfig<MultiselectOption, boolean, GroupBase<MultiselectOption>> => ({
  container: CustomStyles(theme).container,
  menu: CustomStyles(theme).menu,
  menuList: CustomStyles(theme).menuList,
  menuPortal: CustomStyles(theme).menuPortal,
  control: (baseStyles, state) => ({
    display: 'flex',
    padding: (state.selectProps as any).readOnly ? '3px 14px' : '3px 12px',
    pointerEvents: (state.selectProps as any).readOnly ? 'none' : 'auto',
    alignItems: 'center',
    gap: '12px',
    flex: '1 0 0',
    borderRadius: '5px',
    border:
      (state.selectProps as any).readOnly && !state.isDisabled
        ? 'none'
        : state.selectProps['aria-invalid']
          ? `2px solid ${theme.midwestColors.sentimentNegativeStrong}`
          : state.isDisabled
            ? `2px solid ${theme.midwestColors.borderDisabled}`
            : `2px solid ${theme.midwestColors.neutralLightHeavy}`,
    ':hover': {
      border:
        !state.selectProps.menuIsOpen &&
        !state.isDisabled &&
        !(state.selectProps as any).readOnly
          ? `2px solid ${theme.midwestColors.neutralDarkHeavy}`
          : '',
    },
    background:
      (state.selectProps as any).readOnly && !state.isDisabled
        ? `${theme.midwestColors.textSecondaryDarkBG}`
        : state.isDisabled
          ? `${theme.midwestColors.formBackgroundDisabled}`
          : `${theme.midwestColors.neutralLightWeak}`,
    boxSizing: 'border-box',
    minHeight: '48px',
    overflow: 'hidden',
  }),
  valueContainer: (baseStyles, state) => ({
    display: 'flex',
    width: '100%',
    flexWrap: state.selectProps.menuIsOpen ? 'wrap' : 'nowrap',
    padding: state.hasValue ? '0' : '8px 0px',
    gap: 8,
    color: `${theme.midwestColors.textPrimary}`,
  }),
  placeholder: () => ({
    display: 'flex',
    color: `${theme.midwestColors.textPrimary}`,
  }),
  input: (baseStyles, state) => ({
    display: 'flex',
    width: !state.selectProps.menuIsOpen ? '100%' : '',
    color: `${theme.midwestColors.textPrimary}`,
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    cursor: state.isDisabled ? 'not-allowed' : '',
  }),
  option: (baseStyles, state) => ({
    background: state.isFocused
      ? `${theme.midwestColors.neutralLightMedium}`
      : `${theme.midwestColors.neutralLightWeak}`,
    padding: 12,
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '145%',
    fontWeight: 400,
    color: state.isFocused
      ? `${theme.midwestColors.identityBrandPrimaryHeavy}`
      : `${theme.midwestColors.textSecondary}`,
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
  }),
  multiValue: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    background: `${theme.midwestColors.identityBrandPrimaryWeak}`,
  }),
});
