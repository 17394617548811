import { BadgeSizes } from './Badge';
import { MDSText } from '@midwest/web/base';
import styled, { css } from 'styled-components';

export const BaseBadgeStyle = css`
  border-radius: 1000px;
  display: inline-flex;
`;

export const PositiveBadgeStyle = css`
  color: ${(p) => p.theme.midwestColors.sentimentSuccessDense};
  background: ${(p) => p.theme.midwestColors.sentimentSuccessWeak};
  path {
    fill: ${(p) => p.theme.midwestColors.sentimentSuccessDense};
  }
`;

export const WarningBadgeStyle = css`
  color: ${(p) => p.theme.midwestColors.sentimentWarningDense};
  background: ${(p) => p.theme.midwestColors.sentimentWarningWeak};
  path {
    fill: ${(p) => p.theme.midwestColors.sentimentWarningDense};
  }
`;

export const NegativeBadgeStyle = css`
  color: ${(p) => p.theme.midwestColors.sentimentNegativeHeavy};
  background: ${(p) => p.theme.midwestColors.sentimentNegativeFaint};
  path {
    fill: ${(p) => p.theme.midwestColors.sentimentNegativeHeavy};
  }
`;

export const NeutralBadgeStyle = css`
  color: ${(p) => p.theme.midwestColors.textPrimary};
  background: ${(p) => p.theme.midwestColors.backgroundDefault};
  box-shadow: inset 0 0 0 ${(p) => p.theme.border.widthDefault}
    ${(p) => p.theme.midwestColors.borderDefault};
  path {
    fill: ${(p) => p.theme.midwestColors.textPrimary};
  }
`;

export const InfoBadgeStyle = css`
  color: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
  background: ${(p) => p.theme.midwestColors.backgroundDefault};
  box-shadow: inset 0 0 0 ${(p) => p.theme.border.widthDefault}
    ${(p) => p.theme.midwestColors.borderDefault};
  path {
    fill: ${(p) => p.theme.midwestColors.identityBrandPrimaryStrong};
  }
`;

export const LargeLabel = styled(MDSText.BodyHeavyDefault)`
  margin: 0;
  padding: 5px ${(p) => p.theme.spacing.xsm} 5px ${(p) => p.theme.spacing.xsm};
  white-space: nowrap;
`;

export const SmallLabel = styled(MDSText.BodyHeavySm)`
  padding: ${(p) => p.theme.spacing.xxxsm} ${(p) => p.theme.spacing.xsm};
  white-space: nowrap;
`;

export const LeftIconLayout = styled.div<{
  size: BadgeSizes;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.xsm};
  margin-right: -${(p) => p.theme.spacing.xxsm};
`;

export const CustomIcon = styled.div<{ size: BadgeSizes }>`
  width: ${(p) => (p.size === 'small' ? '12px' : '16px')};
  height: ${(p) => (p.size === 'small' ? '12px' : '16px')};
`;
